/*CONFIG*/
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");

/* TYPOGRAPHY STYLE UI */
:root {
  --primarycolor: #f78f2d;
  --secondarycolor: #146daf;
  --whitecolor: #fff;
  --blackcolor: #000;
  --lightorangecolor1: #f3e5d9;
  --lightorangecolor2: #ffe2c6;
  --lightorangecolor3: #f78f2d40;
  --lightorangecolor4: #f7b271;
  --lightorangecolor5: #f6781b19;
  --redcolor1: #ff7561;
  --bluecolor1: #0076ce;
  --bluecolor2: #0078d1;
  --darkbluecolor1: #01375f;
  --darkbluecolor2: #003760;
  --darkbluecolor3: #0a416a;
  --darkbluecolor4: #052884;
  --graycolor1: #00000099;
  --graycolor2: #00000033;
  --graycolor3: #707070;
  --graycolor4: #00000066;
  --graycolor5: #0000001a;
  --graycolor6: #999999;
  --graycolor7: #d4d4d4;
  --graycolor8: #0000004f;
  --graycolor5: #c5c5c5;
  --graycolor6: #0000004c;
  --graycolor7: #707070e5;
  --graycolor8: #000000b3;
  --graycolor9: #f5f5f5;
  --body_color: #f9f9f9;
  --lightwhitecolor1: #fafafa;
  --txt_color_red: #e83c42;
  --txt_color_orange: #f47023;
  --txt_color_yellow: #ffbc00;
  --txt_color_green: #4eaf6c;
  --lightbluecolor1: #265477;
  --lightbluecolor2: #3498e3ad;
  --lightbluecolor3: #f6fbff;
  --lightbluecolor4: #cceaf8;
  --lightbluecolor5: #dbeffd;
  --lightbluecolor6: #e4f0f8;
  --lightbluecolor7: #e4ebf8;
  --lightbluecolor8: #d8ebf9ad;
  --lightbluecolor9: #cadeed;
  --lightbluecolor10: #74b5e6;
  --lightgraycolor1: #0000000f;
  --lightgraycolor2: #d9d9d933;
  --lightgraycolor3: #f8f9fb;
  --lightgraycolor4: #f6f6f6;
  --lightgraycolor5: #e6e6e6;
  --lightgraycolor6: #0000004d;
  --lightgraycolor7: #eeeeee;
  --lightbrowncolor1: #e39c59;
  --lightredcolor1: #f9ebe5;
  --lightredcolor2: #ff8c8c;
  --lightgreencolor1: #9dffa3;
  --lightgreencolor2: #5eda98;
  --lightgreencolor3: #c4ffc8;
  --greencolor1: #16a947;
  --subhead_color: #000000e5;
  --txt_color: #000000cc;
  --nav_color: #8b8b8b;
  --txt_list_color: #2a2a2a;
  /*//=== FONT FAMILY ===//*/
  --body-font: "Fira Sans", sans-serif;
  --heading-font: "Raleway", sans-serif;
  --cta-font: "Poppins", sans-serif;
  /*//==== FONT SIZE ====//*/
  --subheading1: 45px;
  --subheading2: 40px;
  --subheading3: 32px;
  --subheading4: 35px;
  --subheading5: 30px;
  --subheading6: 28px;
  --subheading61: 26px;
  --subheading7: 22px;
  --subheading8: 24px;
  --txt_normal: 16px;
  --txt_xxl: 25px;
  --txt_xl: 20px;
  --txt_lg: 18px;
  --txt_xxs: 14px;
  --txt_xxxxs: 13px;
  --txt_xxxs: 12px;
  --txt_xxxxs: 11px;
  /*FONT WEIGHT*/
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-bolder: 800;
  --font-black: 900;
  --fs10: 10px;
  --fs11: 11px;
  --fs12: 12px;
  --fs13: 13px;
  --fs14: 14px;
  --fs15: 15px;
  --fs16: 16px;
  --fs17: 17px;
  --fs18: 18px;
  --fs20: 20px;
  --fs22: 22px;
  --fs23: 23px;
  --fs24: 24px;
  --fs26: 26px;
  --fs28: 28px;
  --fs30: 30px;
  --fs32: 32px;
  --fs33: 33px;
  --fs34: 34px;
  --fs37: 37px;
  --fs40: 40px;
  --fs42: 42px;
  --fs43: 43px;
  --fs50: 50px;
  --fs52: 52px;
  --fs70: 70px;
  --fs100: 100px;
}

/*BASE*/
/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--heading-font);
  font-size: var(--txt_normal);
  background: var(--body_color);
  color: var(--txt_color);
}

.just-text > * {
  font-family: var(--heading-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--subhead_color);
  font-weight: var(--font-semibold);
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button,
input {
  border: none;
  outline: none;
}

button:focus,
input:focus,
textarea:focus {
  border: none;
  outline: none;
  box-shadow: initial;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths,
.col-xl-5ths,
.col-xxl-5ths,
.col-xs-two5ths,
.col-sm-two5ths,
.col-md-two5ths,
.col-lg-two5ths,
.col-xl-two5ths,
.col-xxl-two5ths,
.col-xs-three5ths,
.col-sm-three5ths,
.col-md-three5ths,
.col-lg-three5ths,
.col-xl-three5ths,
.col-xxl-three5ths,
.col-xs-four5ths,
.col-sm-four5ths,
.col-md-four5ths,
.col-lg-four5ths,
.col-xl-four5ths,
.col-xxl-four5ths,
.col-xs-five5ths,
.col-sm-five5ths,
.col-md-five5ths,
.col-lg-five5ths,
.col-xl-five5ths,
.col-xxl-five5ths {
  position: relative;
  min-height: 1px;
}
.logo_appointment_flow {
  height: auto;
  width: 157px;
  position: absolute;
  left: 24px;
  top: 24px;
}

.custom-disabled {
  background-color: #e9ecef;
  cursor: not-allowed !important;
}

.txt_color_gray {
  color: #aaa;
}

.txt_help {
  font-size: var(--fs12);
  font-family: var(--ffpoppins);
  color: var(--graycolorthree);
  display: block;
  margin: 10px 0 0;
  text-align: left;
}

@media (min-width: 576px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }

  .col-sm-two5ths {
    width: 40%;
    float: left;
  }

  .col-sm-three5ths {
    width: 60%;
    float: left;
  }

  .col-sm-four5ths {
    width: 80%;
    float: left;
  }
}

@media (min-width: 768px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }

  .col-md-two5ths {
    width: 40%;
    float: left;
  }

  .col-md-three5ths {
    width: 60%;
    float: left;
  }

  .col-md-four5ths {
    width: 80%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }

  .col-lg-two5ths {
    width: 40%;
    float: left;
  }

  .col-lg-three5ths {
    width: 60%;
    float: left;
  }

  .col-lg-four5ths {
    width: 80%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-xl-5ths {
    width: 20%;
    float: left;
  }

  .col-xl-two5ths {
    width: 40%;
    float: left;
  }

  .col-xl-three5ths {
    width: 60%;
    float: left;
  }

  .col-xl-four5ths {
    width: 80%;
    float: left;
  }
}

@media (min-width: 1400px) {
  .col-xxl-5ths {
    width: 20%;
    float: left;
  }

  .col-xxl-two5ths {
    width: 40%;
    float: left;
  }

  .col-xxl-three5ths {
    width: 60%;
    float: left;
  }

  .col-xxl-four5ths {
    width: 80%;
    float: left;
  }
}

.field_otp {
  -webkit-appearance: none;
  margin: 0;
}

.backtop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50%;
  background: #f78f2d;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: var(--whitecolor) !important;
  z-index: 99;
}

.modal .modal-body {
  font-family: var(--body-font);
}

/*PAGES*/
/*BELOW CODE FOR HOME PAGE*/

.clinic_list_banner {
  background: #0a416a 0% 0% no-repeat padding-box;
}

.clinic_list_banner .clinic_list_container {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: start;
  overflow: auto;
}

.clinic_list_banner .clinic_list {
  display: flex;
  margin-left: 20px;
}

.clinic_list_banner .clinic_list .clinic_list_item {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: 194px;
  margin: 0 10px;
  font: normal normal normal 18px/24px Fira Sans;
  letter-spacing: 0px;
  color: #f78f2d;
  cursor: pointer;
}

.clinic_list_banner .clinic_list .clinic_list_item.active {
  color: #ffffff;

  background: #f78f2d 0% 0% no-repeat padding-box;
}

.clinic_list_banner.clinic_page .clinic_list .clinic_list_item {
}

.clinic_list_banner .clinic_list .clinic_list_item:last-child {
  margin-right: 0;
}

.clinic_list_banner .clinic_list_banner_title {
  font: 18px / 24px Fira Sans;
  letter-spacing: 0.42px;
  color: #e6f4ff;
  min-width: fit-content;
}
.clinic_list_banner .clinic_list_banner_title_ic4 {
  font-weight: 900;
  font-family: "Archivo Black", sans-serif;
  font-size: 20px;
}

.clinic_list_banner .clinic_list_banner_title .loc_img {
  margin-left: 20px;
}

.faq-section .faq-accordion {
  text-transform: none;
}

.faq-section .faq-accordion .health_condition_list {
  padding-left: 2rem;
  margin-bottom: 1rem;
}
.faq-section .faq-accordion .health_condition_list > li {
  list-style-type: disc;
}

.faq-section .faq-accordion .accordion-header .accordion-button,
.faq-section .faq-accordion .accordion-header .accordion-button::after {
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #146daf;
}

.homepage_content .section_title {
  padding: 20px 0;
  text-align: center;
  background-color: var(--whitecolor);
}

.homepage_content .section_title .headtitle {
  font-size: var(--fs32);
  font-family: var(--heading-font);
  font-weight: var(--font-medium);
  color: var(--subhead_color);
  margin: 0;
}

.homepage_content .banner_content {
  background-color: var(--lightorangecolor1);
}

.homepage_content .banner_content.banner_content_new {
  display: flex;
  align-items: center;
  background: var(--darkbluecolor4) url("../../assets/img/banner/bg_banner.png")
    no-repeat right bottom;
  background-size: 100%;
}

.homepage_content
  .banner_content.banner_content_new
  .inner_banner
  .bigheadtitle {
  /* font-size: var(--fs40);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  color: var(--lightbluecolor7);
  line-height: 60px; */
  text-align: left;
  font: normal normal normal 40px/60px Raleway;
  letter-spacing: 1.2px;
  color: #e4ebf8;
  /*text-transform: capitalize;*/
  opacity: 1;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .banner-left-content {
  margin: 50px 0 0;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .banner-middle-content
  .myth_fact_block
  .inner_mf {
  margin: 0 0 15px;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .banner-middle-content
  .myth_fact_block
  .inner_mf
  .txt_legend {
  color: var(--whitecolor);
  font-size: var(--txt_lg);
  font-family: var(--heading-font);
  font-weight: var(--font-medium);
  line-height: 18px;
  background-color: var(--graycolor8);
  padding: 10px 20px;
  display: inline-block;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -ms-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .banner-middle-content
  .myth_fact_block
  .inner_mf
  .txt_summary {
  background-color: var(--redcolor1);
  padding: 20px;
  color: var(--subhead_color);
  font-size: var(--subheading5);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  display: block;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .banner-middle-content
  .myth_fact_block
  .inner_mf
  .txt_summary.br_left {
  -webkit-border-radius: 0 8px 8px 8px;
  -moz-border-radius: 0 8px 8px 8px;
  -ms-border-radius: 0 8px 8px 8px;
  border-radius: 0 8px 8px 8px;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .banner-middle-content
  .myth_fact_block
  .inner_mf
  .txt_summary.br_right {
  -webkit-border-radius: 8px 0 8px 8px;
  -moz-border-radius: 8px 0 8px 8px;
  -ms-border-radius: 8px 0 8px 8px;
  border-radius: 8px 0 8px 8px;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .banner-right-content {
  margin: 50px 0 0;
}

.homepage_content .banner_content .inner_banner .banner-slider .slick-dots {
  bottom: -80px;
}

.homepage_content .banner_content .inner_banner .banner-slider .slick-dots li {
  width: auto;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .slick-dots
  li.slick-active
  button {
  background-color: var(--primarycolor);
  width: 35px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .slick-dots
  li
  button {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--graycolor7);
}

.homepage_content
  .banner_content
  .inner_banner
  .banner-slider
  .slick-dots
  li
  button:before {
  display: none;
}

.homepage_content .section_suffering {
  background-color: var(--secondarycolor);
  overflow: hidden;
  position: relative;
}

.homepage_content .section_suffering .inner_suff .left_stuff_content {
  position: relative;
  top: 35px;
  z-index: 9;
}

.homepage_content .section_suffering .inner_suff .right_suff_content {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .title_block {
  margin: 0 0 30px;
  text-align: center;
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .title_block
  .headtitle {
  color: var(--whitecolor);
  font-size: var(--fs32);
  font-weight: var(--font-bold);
  font-family: var(--heading-font);
  line-height: 50px;
  margin: 0;
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .list_suff {
  margin: 0 auto 80px;
  text-align: left;
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .list_suff
  li {
  width: calc(100% / 3);
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .list_suff
  li:not(:last-child) {
  margin: 0 60px 0 0;
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .list_suff
  li
  .box {
  display: flex;
  align-items: center;
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .list_suff
  li
  .box
  .icon {
  width: 50px;
  height: 50px;
  margin: 0 8px 0 0;
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .list_suff
  li
  .box
  .icon
  img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.homepage_content
  .section_suffering
  .inner_suff
  .right_suff_content
  .list_suff
  li
  .box
  .subtitle {
  color: var(--whitecolor);
  font-size: var(--txt_xl);
  font-weight: var(--font-medium);
  font-family: var(--heading-font);
  width: calc(100% - 58px);
  white-space: nowrap;
}

.homepage_content .section_sc {
  background-color: var(--lightbluecolor5);
  position: relative;
  overflow: hidden;
}

.homepage_content .section_sc .title_block {
  margin: 0 0 30px !important;
}

.homepage_content .section_sc .title_block .headtitle {
  font-size: var(--fs32);
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  color: var(--subhead_color);
  line-height: 58px;
}

.homepage_content .section_sc .inner_sc {
  text-align: center;
}

.homepage_content .section_sc .inner_sc .box_sc {
  margin: 0 0 25px;
}

.homepage_content .section_sc .inner_sc .box_sc .mr {
  margin-right: 10px;
  text-align: right;
}

.homepage_content .section_sc .inner_sc .box_sc .ml {
  margin-left: 10px;
  text-align: left;
}

.homepage_content .section_sc .inner_sc .box_sc .subtitle {
  font-size: var(--txt_xl);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  color: var(--subhead_color);
  width: calc(100% - 160px);
}

.homepage_content .section_sc .inner_sc .box_sc .thumb {
  width: 110px;
  height: 110px;
}

.homepage_content .section_sc .inner_sc .box_sc .thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.homepage_content .section_diagnoises {
  background: var(--whitecolor) url(../../assets/img/homec/wave.png) no-repeat
    bottom;
  background-size: 100%;
  animation: bgwave 2s linear infinite;
  overflow: hidden;
}

.homepage_content .section_diagnoises .left_dia_content {
  text-align: center;
}

.homepage_content .section_diagnoises .right_dia_content .title_block {
  margin: 0 0 30px;
  text-align: center;
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .title_block
  .headtitle {
  font-size: var(--fs32);
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  color: var(--subhead_color);
  line-height: 58px;
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .appt_content
  .subtitle {
  font-size: var(--txt_lg);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  color: var(--subhead_color);
  line-height: 32px;
  margin: 0 0 10px;
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .appt_content
  .box_appt {
  -webkit-border-radius: 15px 0 15px 0;
  -moz-border-radius: 15px 0 15px 0;
  -ms-border-radius: 15px 0 15px 0;
  border-radius: 15px 0 15px 0;
  background-color: var(--lightbluecolor6);
  box-shadow: 0px 3px 6px #0000001c;
  -webkit-box-shadow: 0px 3px 6px #0000001c;
  -ms-box-shadow: 0px 3px 6px #0000001c;
  -moz-box-shadow: 0px 3px 6px #0000001c;
  padding: 25px 15px;
  height: 386px;
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .appt_content
  .box_appt
  .link_more {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--primarycolor);
  font-size: var(--txt_xxxs);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .appt_content
  .box_appt
  .head_block {
  margin: 0 0 20px;
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .appt_content
  .box_appt
  .head_block
  .desc {
  font-size: var(--txt_xxs);
  font-family: var(--heading-font);
  font-weight: var(--font-medium);
  color: var(--subhead_color);
  line-height: 23px;
  margin: 0 0 20px;
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .appt_content
  .box_appt
  .body_block
  .list_bullet
  li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.5);
  margin: 0 0 15px;
  padding: 0 0 15px;
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .appt_content
  .box_appt
  .body_block
  .list_bullet
  li
  .maintitle {
  color: var(--subhead_color);
  font-size: var(--txt_xxs);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  margin: 0 0 10px;
}

.homepage_content
  .section_diagnoises
  .right_dia_content
  .appt_content
  .box_appt
  .body_block
  .list_bullet
  li
  .desc {
  font-size: var(--txt_xxs);
  font-family: var(--heading-font);
  font-weight: var(--font-medium);
  color: var(--subhead_color);
  line-height: 16px;
  margin: 0;
}

.homepage_content .section_journey {
  background-color: var(--lightgraycolor3);
}

.homepage_content .section_journey .title_block {
  margin: 0 auto 30px;
}

.homepage_content .section_journey .title_block .headtitle {
  font-size: var(--fs32);
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  color: var(--subhead_color);
  line-height: 58px;
}

.homepage_content .section_journey .speed_meter_block .thumbnail {
  margin: 0 auto 20px;
  padding: 0 20px;
}

.homepage_content .section_journey .speed_meter_block .subtitle {
  font-size: var(--subheading61);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  color: var(--subhead_color);
  line-height: 34px;
  margin: 0 0 30px;
}

.homepage_content
  .section_journey
  .speed_meter_block
  .box_speedmeter
  .smallheadtitle {
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  line-height: 22px;
  margin: 0 0 12px;
}

.homepage_content
  .section_journey
  .speed_meter_block
  .box_speedmeter
  .smallheadtitle.txt_color_red {
  color: var(--txt_color_red);
}

.homepage_content
  .section_journey
  .speed_meter_block
  .box_speedmeter
  .smallheadtitle.txt_color_orange {
  color: var(--txt_color_orange);
}

.homepage_content
  .section_journey
  .speed_meter_block
  .box_speedmeter
  .smallheadtitle.txt_color_yellow {
  color: var(--txt_color_yellow);
}

.homepage_content
  .section_journey
  .speed_meter_block
  .box_speedmeter
  .smallheadtitle.txt_color_green {
  color: var(--txt_color_green);
}

.homepage_content
  .section_journey
  .speed_meter_block
  .box_speedmeter
  .list_bullet
  li:not(:last-child) {
  margin: 0 0 10px;
}

.homepage_content
  .section_journey
  .speed_meter_block
  .box_speedmeter
  .list_bullet
  li
  .dot {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--txt_list_color);
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  float: left;
  position: relative;
  top: 10px;
}

.homepage_content
  .section_journey
  .speed_meter_block
  .box_speedmeter
  .list_bullet
  li
  .txt {
  display: inline-block;
  width: calc(100% - 11px);
  font-size: var(--txt_xxs);
  font-family: var(--heading-font);
  font-weight: var(--font-medium);
}

.homepage_content .section_journey .mobile_slider_block {
  display: block;
}

.homepage_content .section_journey .mobile_slider_block .txt_summary {
  text-align: center;
  font-size: var(--txt_lg);
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  color: var(--txt_color);
  line-height: 30px;
}

.homepage_content .section_journey .mobile_slider_block #sync1 .owl-dots {
  margin: 0 0 30px;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  #sync1
  .owl-dots
  .owl-dot.active
  span {
  background-color: var(--darkbluecolor4);
}

.homepage_content .section_journey .mobile_slider_block #sync1 .owl-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
  position: relative;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  #sync1
  .owl-nav
  .owl-prev {
  left: 5%;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  #sync1
  .owl-nav
  .owl-next {
  right: 5%;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  #sync1
  .owl-nav
  .owl-prev,
.homepage_content
  .section_journey
  .mobile_slider_block
  #sync1
  .owl-nav
  .owl-next {
  top: 0;
  position: absolute;
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.2);
  width: 48px;
  height: 48px;
  background-color: var(--whitecolor);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondarycolor);
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .thumbnail_block {
  position: relative;
  max-width: 280px;
  margin: 0 auto;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .thumbnail_block
  img {
  max-width: 100%;
  width: auto;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .thumbnail_block
  .icon_block {
  position: absolute;
  width: 100px;
  height: 100px;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .thumbnail_block
  .icon_block
  img {
  max-width: 100%;
  width: auto;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .thumbnail_block
  .icon_block.position_top {
  top: 0;
  left: 0;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .thumbnail_block
  .icon_block.position_bottom {
  bottom: 0;
  left: 0;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .caption_slider {
  text-align: center;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .caption_slider
  .maintitle {
  font-size: var(--txt_lg);
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  color: var(--darkbluecolor4);
  margin: 0 0 5px;
}

.homepage_content
  .section_journey
  .mobile_slider_block
  .owl-item
  .item
  .caption_slider
  .desc {
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  font-weight: var(--font-medium);
  color: var(--subhead_color);
  margin: 0 0 20px;
  line-height: 26px;
}

.homepage_content .section_journey .desktop_slider_block .wallpaper_block {
  max-width: 400px;
  margin: auto;
  position: relative;
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .wallpaper_block
  .icon {
  position: absolute;
  width: 150px;
  height: 150px;
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .wallpaper_block
  .icon
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .wallpaper_block
  .icon.position_top {
  top: 0;
  left: 0;
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .wallpaper_block
  .icon.position_bottom {
  bottom: 0;
  left: 0;
}

.homepage_content .section_journey .desktop_slider_block .custom_acc {
  position: relative;
}

.homepage_content .section_journey .desktop_slider_block .custom_acc:before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  height: 85%;
  width: 2px;
  background-color: var(--lightgraycolor7);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item {
  border: 0;
  background-color: transparent;
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(1)
  .custom_acchead
  .btn_acc:before {
  border: 5px solid var(--secondarycolor);
  background: var(--whitecolor);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(1)
  .custom_acchead
  .btn_acc.collapsed:before {
  border: 5px solid var(--lightgraycolor3);
  background-color: var(--secondarycolor);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(2)
  .custom_acchead
  .btn_acc:before {
  border: 5px solid var(--lightredcolor2);
  background: var(--whitecolor);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(2)
  .custom_acchead
  .btn_acc.collapsed:before {
  border: 5px solid var(--lightgraycolor3);
  background-color: var(--lightredcolor2);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(3)
  .custom_acchead
  .btn_acc:before {
  border: 5px solid var(--lightbluecolor10);
  background: var(--whitecolor);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(3)
  .custom_acchead
  .btn_acc.collapsed:before {
  border: 5px solid var(--lightgraycolor3);
  background-color: var(--lightbluecolor10);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(4)
  .custom_acchead
  .btn_acc:before {
  border: 5px solid var(--lightorangecolor4);
  background: var(--whitecolor);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(4)
  .custom_acchead
  .btn_acc.collapsed:before {
  border: 5px solid var(--lightgraycolor3);
  background-color: var(--lightorangecolor4);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(5)
  .custom_acchead
  .btn_acc:before {
  border: 5px solid var(--lightgreencolor2);
  background: var(--whitecolor);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item:nth-child(5)
  .custom_acchead
  .btn_acc.collapsed:before {
  border: 5px solid var(--lightgraycolor3);
  background-color: var(--lightgreencolor2);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item
  .custom_acchead {
  border: 0;
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item
  .custom_acchead
  .btn_acc {
  background-color: transparent;
  outline: none;
  box-shadow: initial;
  font-size: var(--subheading8);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  color: var(--blackcolor);
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item
  .custom_acchead
  .btn_acc:after {
  display: none;
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item
  .custom_acchead
  .btn_acc:before {
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 20px;
  left: -9px;
  width: 20px;
  height: 20px;
}

.homepage_content
  .section_journey
  .desktop_slider_block
  .custom_acc
  .custom_item
  .custom_accbody
  .desc {
  color: var(--blackcolor);
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  font-weight: var(--font-medium);
  line-height: 32px;
  margin: 0;
}

.homepage_content .section_journey .desktop_slider_block .txt_summary {
  color: var(--blackcolor);
  font-size: var(--subheading8);
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  margin: 25px 0;
  text-align: center;
}

.homepage_content .section_cp {
  background-color: var(--lightbluecolor3);
}

.homepage_content .section_cp.bg_lightgray {
  background-color: var(--whitecolor);
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .treatment_slider
  .fa.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 999;
  background-color: var(--primarycolor);
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: var(--whitecolor);
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .treatment_slider
  .fa-chevron-left.slick-arrow {
  left: 0;
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .treatment_slider
  .fa-chevron-right.slick-arrow {
  right: 0;
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .treatment_slider
  .slick-dots {
  bottom: 0;
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .treatment_slider
  .slick-dots
  li {
  width: auto;
  height: auto;
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .treatment_slider
  .slick-dots
  li.slick-active
  button {
  background: var(--secondarycolor);
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .treatment_slider
  .slick-dots
  li
  button {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: var(--primarycolor);
  border-radius: 50%;
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .treatment_slider
  .slick-dots
  li
  button:before {
  display: none;
}

.homepage_content .section_cp.bg_lightgray .inner_cp .box_cp .caption {
  min-height: 275pt;
}

.homepage_content .section_cp.bg_lightgray .inner_cp .box_cp .caption .desc {
  min-height: 225px;
}

.homepage_content
  .section_cp.bg_lightgray
  .inner_cp
  .box_cp
  .caption
  .linkmore {
  color: var(--primarycolor);
  font-size: var(--txt_xxs);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
}

.homepage_content .section_cp .title_block {
  margin: 0 0 45px;
  text-align: center;
}

.homepage_content .section_cp .title_block .headtitle {
  font-size: var(--fs32);
  font-weight: var(--font-medium);
  font-family: var(--heading-font);
  color: var(--subhead_color);
  line-height: 40px;
  margin: 0 0 40px;
}

.homepage_content .section_cp .title_block .desc {
  font-size: var(--txt_lg);
  font-weight: var(--font-regular);
  font-family: var(--heading-font);
  color: var(--txt_color);
  line-height: 30px;
  margin: 0;
}

.homepage_content .section_cp .inner_cp .box_cp_b {
  padding: 0 30px;
  margin: 0 0 60px;
}

.homepage_content .section_cp .inner_cp .box_cp_b .thumbnail {
  margin: 0 0 20px;
}

.homepage_content .section_cp .inner_cp .box_cp_b .caption .subheadtitle {
  font-size: var(--subheading7);
  font-weight: var(--font-semibold);
  font-family: var(--heading-font);
  color: var(--subhead_color);
  line-height: 26px;
  margin: 0 0 10px;
}

.homepage_content .section_cp .inner_cp .box_cp_b .caption .desc {
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  color: var(--graycolor1);
  line-height: 26px;
}

.homepage_content .section_cp .inner_cp .box_cp {
  text-align: center;
}

.homepage_content .section_cp .inner_cp .box_cp .thumbnail {
  background-color: var(--lightbluecolor4);
  box-shadow: -2px 3px 2px #00000029;
  -webkit-box-shadow: -2px 3px 2px #00000029;
  padding: 20px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_content .section_cp .inner_cp .box_cp .caption {
  background-color: var(--lightorangecolor2);
  padding: 20px;
  min-height: 230pt;
  position: relative;
}

.homepage_content .section_cp .inner_cp .box_cp .caption .arrow_triangle {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

.homepage_content
  .section_cp
  .inner_cp
  .box_cp
  .caption
  .arrow_triangle
  .img_fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepage_content .section_cp .inner_cp .box_cp .caption .subheadtitle {
  font-size: var(--subheading7);
  font-weight: var(--font-semibold);
  font-family: var(--heading-font);
  color: var(--subhead_color);
  line-height: 26px;
  margin: 40px 0 20px;
}

.homepage_content .section_cp .inner_cp .box_cp .caption .desc {
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  color: var(--txt_color);
  line-height: 26px;
  text-align: left;
}

.homepage_content .section_arthritis {
  background-color: var(--lightbluecolor3);
}

.homepage_content .section_arthritis .title_block {
  margin: 0 auto 30px;
  text-align: center;
}

.homepage_content .section_arthritis .title_block .headtitle {
  color: var(--subhead_color);
  font-size: var(--fs32);
  font-weight: var(--font-bold);
  font-family: var(--heading-font);
  line-height: 50px;
  margin: 0 0 20px;
}

.homepage_content
  .section_arthritis
  .inner_arthritis
  .gridcol:not(:last-child)
  .box_arthritis
  .divider {
  width: 100%;
  height: 5px;
  background-color: var(--secondarycolor);
  display: block;
  position: absolute;
  top: 18%;
  right: -100px;
  z-index: 1;
}

.homepage_content .section_arthritis .inner_arthritis .box_arthritis {
  position: relative;
}

.homepage_content
  .section_arthritis
  .inner_arthritis
  .box_arthritis
  .thumbnail {
  margin: 0 auto 40px;
  position: relative;
  z-index: 9;
  width: 150px;
  height: 150px;
}

.homepage_content
  .section_arthritis
  .inner_arthritis
  .box_arthritis
  .thumbnail
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepage_content
  .section_arthritis
  .inner_arthritis
  .box_arthritis
  .caption
  .subheadtitle {
  font-size: var(--subheading8);
  font-weight: var(--font-medium);
  font-family: var(--heading-font);
  color: var(--subhead_color);
  white-space: nowrap;
  margin: 0 0 20px;
}

.homepage_content
  .section_arthritis
  .inner_arthritis
  .box_arthritis
  .caption
  .desc {
  font-size: var(--txt_normal);
  font-weight: var(--font-regular);
  font-family: var(--heading-font);
  color: var(--subhead_color);
  line-height: 24px;
  margin: 0;
  height: 110px;
}

.homepage_content
  .section_arthritis
  .inner_arthritis
  .box_arthritis
  .caption
  .link_readmore {
  color: var(--primarycolor);
  font-size: var(--txt_lg);
  font-weight: var(--font-medium);
  font-family: var(--heading-font);
  line-height: 58px;
}

.homepage_content .section_arthritis .list_arthritis {
  position: relative;
}

.homepage_content .section_arthritis .list_arthritis li {
  position: relative;
}

.homepage_content .section_arthritis .list_arthritis li:not(:last-child) {
  margin: 0;
  padding: 0 0 50px;
}

.homepage_content
  .section_arthritis
  .list_arthritis
  li
  .box_arthritis
  .shape_thumb {
  position: relative;
  z-index: 9;
}

.homepage_content
  .section_arthritis
  .list_arthritis
  li
  .box_arthritis
  .caption
  .subheadtitle {
  font-size: var(--subheading3);
  font-weight: var(--font-medium);
  font-family: var(--heading-font);
  color: var(--subhead_color);
  line-height: 45px;
  margin: 0 0 28px;
}

.homepage_content
  .section_arthritis
  .list_arthritis
  li
  .box_arthritis
  .caption
  .desc {
  font-size: var(--txt_normal);
  font-weight: var(--font-regular);
  font-family: var(--heading-font);
  color: var(--subhead_color);
  line-height: 24px;
}

.homepage_content
  .section_arthritis
  .list_arthritis
  li
  .box_arthritis
  .caption
  .link_readmore {
  color: var(--primarycolor);
  font-size: var(--txt_lg);
  font-weight: var(--font-medium);
  font-family: var(--heading-font);
  line-height: 58px;
}

.homepage_content .section_fp {
  background-color: var(--bluecolor1);
}

.homepage_content .section_fp.bg_lineargradient {
  background: transparent
    linear-gradient(180deg, var(--bluecolor2) 0%, var(--darkbluecolor1) 100%) 0%
    0% no-repeat padding-box;
}

.homepage_content .section_fp .title_block {
  margin: 0 auto 30px;
  text-align: center;
}

.homepage_content .section_fp .title_block .headtitle {
  color: var(--whitecolor);
  font-size: var(--fs32);
  font-weight: var(--font-bold);
  font-family: var(--heading-font);
  line-height: 50px;
}

.homepage_content .section_fp .feedback_slider .item {
  padding: 0 10px;
  height: 100%;
}

.homepage_content .section_fp .feedback_slider .item .box_fp {
  border: 1px solid var(--lightbluecolor2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 20px;
  min-height: 250px;
}

.homepage_content .section_fp .feedback_slider .item .box_fp .txt_desc {
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  font-weight: var(--font-regular);
  line-height: 24px;
  margin: 0 0 25px;
  color: var(--whitecolor);
  letter-spacing: 0.48px;
}

.homepage_content .section_fp .feedback_slider .item .box_fp .caption {
  display: flex;
  align-items: center;
}

.homepage_content .section_fp .feedback_slider .item .box_fp .caption .thumb {
  width: 78px;
  margin: 0 15px 0 0;
}

.homepage_content
  .section_fp
  .feedback_slider
  .item
  .box_fp
  .caption
  .thumb
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepage_content
  .section_fp
  .feedback_slider
  .item
  .box_fp
  .caption
  .subheadtitle {
  width: calc(100% - 93px);
  color: var(--whitecolor);
  font-size: var(--txt_normal);
  font-weight: var(--font-regular);
  font-family: var(--heading-font);
  line-height: 34px;
  opacity: 0.8;
}

.homepage_content .section_fp .feedback_slider .slick-dots {
  bottom: -60px;
}

.homepage_content .section_fp .feedback_slider .slick-dots li {
  width: auto;
}

.homepage_content
  .section_fp
  .feedback_slider
  .slick-dots
  li.slick-active
  button {
  background-color: var(--primarycolor);
  width: 35px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  opacity: 1;
}

.homepage_content .section_fp .feedback_slider .slick-dots li button {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--whitecolor);
  opacity: 0.6;
}

.homepage_content .section_fp .feedback_slider .slick-dots li button:before {
  display: none;
}

.homepage_content .section_experts {
  overflow: hidden;
  background: transparent
    linear-gradient(180deg, var(--bluecolor2) 0%, var(--darkbluecolor1) 100%) 0%
    0% no-repeat padding-box;
  border-bottom: 1px solid var(--lightbluecolor1);
}

.homepage_content .section_experts .mob_frame {
  text-align: center;
}

.homepage_content .section_experts .experts_content {
  padding: 0 80px 50px 20px;
}

.homepage_content .section_experts .experts_content .headtitle {
  color: var(--whitecolor);
  font-size: var(--subheading1);
  font-weight: var(--font-regular);
  font-family: var(--heading-font);
  line-height: 54px;
  margin: 0 0 40px;
}

.homepage_content .section_experts .experts_content .list_bullet {
  margin: 0 0 50px;
}

.homepage_content
  .section_experts
  .experts_content
  .list_bullet
  li:not(:last-child) {
  margin: 0 0 40px;
}

.homepage_content .section_experts .experts_content .list_bullet li .dot {
  width: 12px;
  height: 12px;
  background-color: var(--whitecolor);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px 0 0;
  position: relative;
  top: 10px;
  float: left;
}

.homepage_content .section_experts .experts_content .list_bullet li .txt {
  color: var(--whitecolor);
  font-size: var(--txt_xxl);
  font-weight: var(--font-medium);
  font-family: var(--heading-font);
  display: inline-block;
  width: calc(100% - 32px);
}

.homepage_content .section_experts .experts_content .download_app_block {
  text-align: center;
}

.homepage_content
  .section_experts
  .experts_content
  .download_app_block
  .subtitle {
  font-size: var(--subheading4);
  font-weight: var(--font-medium);
  font-family: var(--heading-font);
  color: var(--primarycolor);
  margin: 0 0 15px;
}

.homepage_content
  .section_experts
  .experts_content
  .download_app_block
  .list_app {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_content
  .section_experts
  .experts_content
  .download_app_block
  .list_app
  li:not(:last-child) {
  margin: 0 5px 0 0;
}

/* CLINIC LANDING PAGES */

.clinic_page_content_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-top: 30px;
}

.clinic_page_content_container .clinic-doctors-list-container {
  padding: 15px;
}

.clinic_page_content_container
  .clinic-doctors-list-container
  .clinic-doctors-list-title1 {
  font: normal normal 600 28px/24px Fira Sans;
}
.clinic_page_content_container
  .clinic-doctors-list-container
  .clinic-doctors-list-title2 {
  font: normal normal 600 28px/24px Fira Sans;
  color: #146daf;
  padding-left: 15px;
}
.clinic_page_content_container
  .clinic-doctors-list-container
  .clinic-doctors-list-title3 {
  font: normal normal 600 18px/21px Raleway;
  padding-left: 15px;
}

.clinic-doctors-list {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

.clinic-doctors-list .clinic-doctors-list-item {
  box-shadow: 0px 2px 8px #0000001a;
  border-radius: 5px;
  width: 350px;
  margin: 0 10px;
  display: block;
  position: relative;
  cursor: pointer;
}
.ba_doctor_content_container .clinic-doctors-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 462px;
  overflow-y: auto;
  margin: 0;
  padding: 10px 20px;
}

.ba_doctor_content_container .clinic-doctors-list .clinic-doctors-list-item {
  max-width: 400px;
  margin: 10px 10px;
  width: 89%;
}

.ba_doctor_content_container
  .clinic-doctors-list
  .clinic-doctors-list-item
  .field_input_radio {
  display: none;
}

.ba_doctor_content_container
  .clinic-doctors-list
  .clinic-doctors-list-item
  .field_lbl {
  display: block;
  position: relative;
}

.ba_doctor_content_container
  .clinic-doctors-list
  .clinic-doctors-list-item
  .field_lbl
  .doctor_view_profile_btn {
  color: #fff;
  background-color: #146daf;
  width: 111px;
  height: 27px;
  position: absolute;
  transform: rotate(90deg);
  top: 42px;
  right: -69px;
  padding: 2px;
  text-align: center;
  vertical-align: baseline;
  font-size: 14px;
}

.ba_doctor_content_container
  .clinic-doctors-list
  .clinic-doctors-list-item
  .field_lbl
  .field_input_radio:checked
  ~ .inner-container {
  background-color: #146daf;
}

.ba_doctor_content_container
  .clinic-doctors-list
  .clinic-doctors-list-item
  .field_lbl
  .field_input_radio:checked
  ~ .inner-container
  .doctor-info,
.ba_doctor_content_container
  .clinic-doctors-list
  .clinic-doctors-list-item
  .field_lbl
  .field_input_radio:checked
  ~ .inner-container
  .doctor-info
  .doctor-name {
  color: #ffffff;
}

.clinic-doctors-list .clinic-doctors-list-item .inner-container {
  padding: 15px 20px;
}

.clinic-doctors-list .clinic-doctors-list-item .earliest-availiblity {
  color: #146daf;
  background: #e6f4ff 0% 0% no-repeat padding-box;
  text-align: center;
  padding: 15px 20px;
  font: normal normal normal 12px/18px Poppins;
}

.clinic-doctors-list
  .clinic-doctors-list-item
  .clinic-doctor-list-item-content {
  display: flex;
}

.clinic-doctors-list
  .clinic-doctors-list-item
  .clinic-doctor-list-item-content
  .doctor-img {
  height: 100px;
  width: 100px;
}

.clinic-doctors-list
  .clinic-doctors-list-item
  .clinic-doctor-list-item-content
  .doctor-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font: normal normal normal 13px/17px Poppins;
  letter-spacing: 0px;
  color: #404040;
}

.clinic-doctors-list
  .clinic-doctors-list-item
  .clinic-doctor-list-item-content
  .doctor-name {
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #146daf;
}

.clinic-doctors-list
  .clinic-doctors-list-item
  .clinic-doctors-list-item-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.clinic-doctors-list
  .clinic-doctors-list-item
  .clinic-doctors-list-item-buttons
  .view-profile-btn {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 10px;
  font: normal normal 600 14px/16px Raleway;
  letter-spacing: 0px;
  color: #333333;
}

.clinic-doctors-list
  .clinic-doctors-list-item
  .clinic-doctors-list-item-buttons
  .book-appointment-btn {
  background: #f78f2d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 10px;
  font: normal normal 600 14px/16px Raleway;
  letter-spacing: 0px;
  color: #ffffff;
}

.inner_content {
  margin: 0 0 50px;
}

.inner_content .title_nav_block {
  display: flex;
  align-items: center;
  padding: 25px 0;
}

.inner_content .title_nav_block .headtitle {
  color: var(--primarycolor);
  font-size: var(--subheading3);
  font-family: var(--heading-font);
  font-weight: var(--font-regular);
  line-height: 32px;
  margin: 0;
}

.inner_content .title_nav_block .list_navsection {
  display: flex;
  align-items: center;
}

.inner_content .title_nav_block .list_navsection li:not(:last-child) {
  margin: 0 10px 0 0;
}

.inner_content .title_nav_block .list_navsection li a {
  color: var(--txt_color);
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  height: 52px;
  line-height: 52px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  border: 1px solid var(--lightgraycolor1);
  text-align: center;
  white-space: nowrap;
  display: block;
  background-color: var(--whitecolor);
  padding: 0 15px;
}

.inner_content .title_nav_block .list_navsection li a.active {
  background-color: var(--secondarycolor);
  color: var(--whitecolor);
  border-color: var(--secondarycolor);
}

.inner_content .custom_nav {
  margin: 50px 0 0;
  border: 0;
}

.inner_content .custom_nav .nav-item {
  width: calc(100% / 3);
}

.inner_content .custom_nav .nav-item .nav-link {
  background-color: transparent;
  padding: 20px;
  color: var(--graycolor2);
  font-size: var(--subheading3);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  text-align: center;
  border: 0;
}

.inner_content .custom_nav .nav-item .nav-link.active {
  color: var(--primarycolor);
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  border-radius: 20px 20px 0 0;
  background-color: var(--whitecolor);
}

.inner_content .section_padding .subheadtitle {
  color: var(--subhead_color);
  font-size: var(--subheading61);
  font-weight: var(--font-semibold);
  font-family: var(--body-font);
  line-height: 24px;
  margin: 0 0 20px;
}

.inner_content .section_padding .txt_caption {
  color: var(--txt_color);
  font-size: var(--txt_normal);
  font-weight: var(--font-medium);
  font-family: var(--body-font);
  display: inline-block;
  margin: 15px 0;
  text-align: center;
  width: 100%;
}

.inner_content .section_padding .imgthumbnail_block .wallpaper_block {
  margin: 0 0 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 3px #00000029;
  -webkit-box-shadow: 0px 2px 3px #00000029;
  -moz-box-shadow: 0px 2px 3px #00000029;
  padding: 15px;
}

.inner_content .section_padding .imgthumbnail_block .wallpaper_block .img_fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inner_content .section_padding .imgthumbnail_block .desc_block .desc {
  color: var(--txt_color);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 26px;
}

.inner_content
  .section_padding
  .imgthumbnail_block
  .desc_block
  .desc:not(:last-child) {
  margin: 0 0 10px;
}

.inner_content .section_padding .video_block .maintitle {
  color: var(--txt_color);
  font-size: var(--fs18);
  font-family: var(--body-font);
  font-weight: var(--font-semibold);
  line-height: 24px;
  margin: 0 0 18px;
}

.inner_content .section_padding .video_block .list_video .box_video {
  border: 1px solid var(--graycolor5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 0 0 30px;
}

.inner_content .section_padding .video_block .list_video .box_video.big_height {
  height: 400px;
}

.inner_content
  .section_padding
  .video_block
  .list_video
  .box_video.small_height {
  height: 250px;
}

.inner_content .section_padding .video_block .list_video .box_video .thumbnail {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.inner_content
  .section_padding
  .video_block
  .list_video
  .box_video
  .thumbnail
  .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.inner_content
  .section_padding
  .video_block
  .list_video
  .box_video
  .thumbnail
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inner_content .section_padding .video_block .list_video .box_video .caption {
  height: calc(100% - 80%);
  padding: 20px 10px;
  background-color: var(--lightbrowncolor1);
  display: flex;
  align-items: center;
}

.inner_content
  .section_padding
  .video_block
  .list_video
  .box_video
  .caption
  .subheadtitle {
  color: var(--subhead_color);
  font-size: var(--txt_xxxxs);
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  line-height: 16px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inner_content .section_padding .customnav {
  border-bottom: 1px solid var(--graycolor5);
}

.inner_content .section_padding .customnav .nav-item:not(:last-child) {
  margin: 0 50px 0 0;
}

.inner_content .section_padding .customnav .nav-item .nav-link {
  color: var(--graycolor1);
  font-size: var(--subheading7);
  font-family: var(--body-font);
  font-weight: var(--font-semibold);
  display: block;
  padding: 10px 20px;
  position: relative;
  border: 0;
}

.inner_content .section_padding .customnav .nav-item .nav-link.active {
  color: var(--subhead_color);
}

.inner_content .section_padding .customnav .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 3px;
  background-color: var(--secondarycolor);
}

.inner_content .section_padding .custom_tabcontent {
  padding: 20px;
}

.inner_content
  .section_padding
  .custom_tabcontent
  .custom_tabpane
  .vertical_tab_content
  .nav
  .nav-link {
  background-color: var(--lightgraycolor2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: var(--graycolor4);
  font-size: var(--txt_lg);
  font-weight: var(--font-semibold);
  font-family: var(--body-font);
}

.inner_content
  .section_padding
  .custom_tabcontent
  .custom_tabpane
  .vertical_tab_content
  .nav
  .nav-link:not(:last-child) {
  margin: 0 0 10px;
}

.inner_content
  .section_padding
  .custom_tabcontent
  .custom_tabpane
  .vertical_tab_content
  .nav
  .nav-link.active {
  background-color: var(--lightorangecolor3);
  color: var(--subhead_color);
}

.inner_content
  .section_padding
  .custom_tabcontent
  .custom_tabpane
  .summary_block {
  margin: 0 0 40px;
}

.inner_content
  .section_padding
  .custom_tabcontent
  .custom_tabpane
  .summary_block
  .desc {
  color: var(--txt_color);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 26px;
}

.inner_content
  .section_padding
  .custom_tabcontent
  .custom_tabpane
  .summary_block
  .desc:not(:last-child) {
  margin: 0 0 10px;
}

.inner_content
  .section_padding
  .custom_tabcontent
  .custom_tabpane
  .summary_block
  .list_bullet {
  list-style-type: disc;
  list-style-position: outside;
}

.inner_content
  .section_padding
  .custom_tabcontent
  .custom_tabpane
  .summary_block
  .list_bullet
  li:not(:last-child) {
  margin: 0 0 10px;
}

.ba_wrapper_content {
  margin: 0 0 50px;
  padding: 0 0 50px;
  height: calc(100vh - 340px);
  display: flex;
  align-items: center;
  margin-top: 55px;
}

.ba_wrapper_content .information_block {
  padding: 50px 0;
  position: relative;
  z-index: 9;
  /* height: calc(100vh - 300px); */
}

.ba_wrapper_content .init_information_block {
  padding: 50px 0;
  position: relative;
  z-index: 9;
  height: 100%;
}

.ba_wrapper_content .information_block .left_info {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 0;
  width: 100%;
}

.ba_wrapper_content .information_block .left_info .subtitle {
  font-size: 29px;
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  color: var(--subhead_color);
  line-height: 35px;
}

.ba_wrapper_content .inner_body {
  background-color: var(--whitecolor);
  box-shadow: 0px 3px 4px #0000001a;
  -webkit-box-shadow: 0px 3px 4px var(--graycolor5);
  -moz-box-shadow: 0px 3px 4px var(--graycolor5);
  -ms-box-shadow: 0px 3px 4px var(--graycolor5);
  padding: 40px 60px;
  margin: 50px 0;
}

.ba_wrapper_content .inner_body .ty_content {
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.ba_wrapper_content .inner_body .ty_content .desc {
  color: var(--subhead_color);
  font-size: var(--fs16);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
}

.ba_wrapper_content .inner_body .ty_content .txt_num {
  display: block;
  color: var(--subhead_color);
  font-size: var(--fs32);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
}

.ba_wrapper_content .inner_body .ty_content .headtitle {
  color: var(--subhead_color);
  font-size: var(--fs28);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  margin: 0 0 20px;
}

.ba_wrapper_content .inner_body .ty_content .link_back {
  color: var(--primarycolor);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
}

.ba_wrapper_content .inner_body .title_block {
  margin: 0 0 30px;
}

.ba_wrapper_content .inner_body .title_block .txt_id {
  color: var(--graycolor7);
  font-size: var(--fs16);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 20px;
}

.ba_wrapper_content .inner_body .title_block .txt_id .txt_num {
  font-size: var(--fs22);
  font-family: var(--body-font);
  font-weight: var(--font-semibold);
  color: var(--subhead_color);
  display: inline-block;
  padding: 0 5px;
}

.ba_wrapper_content .inner_body .title_block .subtitle {
  color: var(--subhead_color);
  font-size: 29px;
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 35px;
  margin: 0 0 15px;
}

.ba_wrapper_content .inner_body .title_block .desc {
  color: var(--subhead_color);
  font-size: var(--fs18);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 30px;
}

.ba_wrapper_content .inner_body .panel_wrapper .panel_block .panel_title {
  border-bottom: 1px dashed var(--graycolor6);
  padding: 15px 30px;
  margin: 10px 0;
  position: relative;
  color: var(--subhead_color);
  font-size: var(--fs18);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .panel_block
  .panel_title:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 4px;
  height: 34px;
  background-color: var(--primarycolor);
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .panel_block
  .panel_body
  .list_panel
  li {
  border-bottom: 1px dashed var(--graycolor6);
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .panel_block
  .panel_body
  .list_panel
  li
  .box_panel {
  padding: 15px 30px;
  display: flex;
  align-items: flex-start;
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .panel_block
  .panel_body
  .list_panel
  li
  .box_panel
  .left_panel {
  width: 80%;
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .panel_block
  .panel_body
  .list_panel
  li
  .box_panel
  .left_panel
  .txt_number {
  display: block;
  color: var(--subhead_color);
  font-size: var(--fs22);
  font-family: var(--body-font);
  font-weight: var(--font-semibold);
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .panel_block
  .panel_body
  .list_panel
  li
  .box_panel
  .left_panel
  .txt_field {
  color: var(--graycolor7);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  display: block;
  margin: 0 0 5px;
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .panel_block
  .panel_body
  .list_panel
  li
  .box_panel
  .left_panel
  .txt_fill {
  color: var(--subhead_color);
  font-size: var(--fs18);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  display: block;
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .panel_block
  .panel_body
  .list_panel
  li
  .box_panel
  .link_edit {
  width: calc(100% - 80%);
  color: var(--primarycolor);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  text-align: right;
}

.ba_wrapper_content .inner_body .panel_wrapper .checkbox_block {
  margin: 25px;
}

.ba_wrapper_content .inner_body .panel_wrapper .checkbox_block label {
  display: flex;
  position: relative;
  cursor: pointer;
}

.ba_wrapper_content .inner_body .panel_wrapper .checkbox_block label input {
  display: none;
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .checkbox_block
  label
  input:checked
  ~ .dot:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--primarycolor);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .checkbox_block
  label
  .dot:before {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--graycolor2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.ba_wrapper_content .inner_body .panel_wrapper .checkbox_block label .caption {
  margin: 0 0 0 10px;
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .checkbox_block
  label
  .caption
  .txt {
  display: block;
  color: var(--txt_color);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  margin: 0 0 30px;
}

.ba_wrapper_content
  .inner_body
  .panel_wrapper
  .checkbox_block
  label
  .caption
  .link {
  color: var(--secondarycolor);
  font-size: var(--txt_xxs);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
}

/*LAYOUT*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.bg_lightgray {
  background-color: var(--lightgraycolor4);
}

.bg_darkblue {
  background-color: var(--secondarycolor);
}

.spacing_pb {
  padding-bottom: 0;
}

/*#scroll-container{
	transform: initial !important;
}*/
.main_content .section_content {
  position: relative;
  padding: 50px 0;
  display: flex;
  align-items: center;
  /*&:nth-child(2){
  	height: 600px !important;
  }
  &:nth-child(3){
  	height: 600px !important;
  }
  &:nth-child(4){
  	height: 700px !important;
  }
  &:nth-child(5){
  	height: 800px !important;
  }
  &:nth-child(6){
  	height: 680px !important;
  }
  &:nth-child(7){
  	height: 450px !important;
  }*/
}

.main_content .section_content:nth-child(1) {
  height: 500px !important;
}

.main_content .section_padding {
  background-color: var(--whitecolor);
  padding: 30px 20px;
}

.main_content .section_padding:not(:last-child) {
  border-bottom: 1px solid var(--graycolor5);
}

/*START FOR ROADPATH*/
/*.roadpath{
	position: absolute;
	bottom: -7px;
	height: 44px;
	width: 120vw;
	background-color: var(--graycolor5);
	transform: rotateX(45deg) translate(-20vw);
	animation: stripemove 2s linear infinite;
	.stripes{
		  position: absolute;
		  top: 50%;
		  width: 100%;
		  height:5px;
		  background:repeating-linear-gradient(to right, white, white 1vw, white 2vw, transparent 3vw)
	}
	@keyframes stripemove {
	  to {
	    transform: rotateX(45deg) translate(0vw);
	  }
	 }
	 @-webkit-keyframes stripemove {
	  to {
	    transform: rotateX(45deg) translate(0vw);
	  }
	 }
}*/
/*END FOR ROADPATH*/
/*BELOW CODE FOR BUTTON PRIMARY*/
.btn_primary {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: var(--whitecolor);
  font-size: var(--fs18);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: var(--primarycolor);
  padding: 0 20px;
  margin: 20px auto;
}

.backButton2 {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border: 1px solid grey;
  border-radius: 3px;
  opacity: 1;
  padding: 2px 20px;
  color: black;
  text-align: center;
  height: 48px;
  letter-spacing: 0px;
}

/* .btn_primary:hover {
  color: var(--whitecolor);
} */

.component_form .right_module .left_patient_reg {
  margin: 20px;

  font-size: 14px;
}

.component_form .right_module .form_group .btn_primary {
  font-size: var(--txt_xxs);
  height: 35px;
  line-height: 35px;
  /* margin: 10px auto; */
  margin-left: 203px;
  font-family: var(--cta-font);
  font-weight: var(--font-medium);
}

.component_form .right_module .form_group .backButton2 {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border: 1px solid grey;
  border-radius: 3px;
  opacity: 1;
  padding: 2px 20px;
  color: black;
  text-align: center;
  height: 35px;
  letter-spacing: 0px;
}

/*list information*/
.list_info.list_darkblue li a {
  color: var(--lightbluecolor9) !important;
}

.list_info.list_darkblue li a.active,
.list_info.list_darkblue li a:hover {
  color: var(--whitecolor) !important;
}

.list_info li:not(:last-child) {
  margin: 0 0 20px;
}

.list_info li a {
  color: var(--graycolor7);
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  display: block;
  padding: 10px 20px;
  position: relative;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.list_info li a.active,
.list_info li a:hover {
  color: var(--subhead_color);
}

.list_info li a.active:before,
.list_info li a:hover:before {
  background-color: var(--primarycolor);
}

.list_info li a:before {
  content: "";
  background-color: var(--lightgraycolor5);
  width: 4px;
  height: 34px;
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.button_next_btm {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0;
  height: 320px;

  text-align: center;
}

.button_next_btm .fa {
  font-size: var(--fs30);
}

.button_next_btm.bg_button_next_btm {
  background-color: var(--secondarycolor);
}

.button_next_btm.bg_button_next_btm .btn_nxt {
  color: var(--whitecolor);
  margin-left: 20px;
  margin-top: 64px;
}

.btn_nxt .fa {
  font-size: var(--fs30);
}

.button_next_btm.bg_button_next_btm .btn_nxt .fa {
  color: var(--whitecolor);
}

.button_next_btm .btn_nxt {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: var(--whitecolor);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--txt_color);
  margin-left: 100px;
}

.button_next_btm .btn_nxt .fa {
  color: var(--graycolor7);
}

.btn_nxt.btn_gray_color {
  margin-top: 30px;
}

.pi_content {
  max-width: 500px;
}

.pi_content .bigtitle {
  font-size: var(--subheading6);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 35px;
}

.pi_content .txt_para {
  font-size: var(--fs16);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 24px;
}

.pi_content .txt_para:not(:last-child) {
  margin: 0 0 30px;
}

.pi_content.pi_white_content .bigtitle {
  color: var(--whitecolor);
  margin: 0 0 30px;
}

.pi_content.pi_white_content .txt_para {
  color: var(--whitecolor);
}

.btn_back {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  z-index: 99;
}

.btn_back .fa {
  font-size: var(--fs30);
}

.btn_back.btn_white_color {
  color: var(--whitecolor);
}

.btn_back.btn_gray_color {
  color: var(--graycolor7);

  margin: auto;
}

.component_form {
  background-color: var(--whitecolor);
  box-shadow: 0px 3px 4px var(--graycolor5);
  -webkit-box-shadow: 0px 3px 4px var(--graycolor5);
  -moz-box-shadow: 0px 3px 4px var(--graycolor5);
  -ms-box-shadow: 0px 3px 4px var(--graycolor5);
  overflow: hidden;
}

.component_form .left_module {
  position: relative;
  padding: 100px 30px;
  display: flex;
  align-items: center;
  height: 100%;
}

.component_form .left_module.left_align {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.component_form .left_module:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  z-index: 9;
  background: var(--graycolor2);
}

.component_form .left_module .titleform {
  font-size: 29px;
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 42px;
  color: var(--subhead_color);
}

.component_form .left_module_2 .titleform {
  font-size: 29px;
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 42px;
  color: var(--subhead_color);
}

.component_form .left_module .desc {
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 32px;
  color: var(--graycolor7);
}

.component_form .left_module_2 .desc {
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  line-height: 32px;
  color: var(--graycolor7);
}

.component_form .right_module {
  height: 100%;
}

.component_form .right_module .fillform_module {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.component_form .right_module .fillform_module .list_form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.component_form .right_module .fillform_module .list_form.list_select li {
  display: none;
}

.component_form
  .right_module
  .fillform_module
  .list_form.list_select
  li.show_select {
  display: flex;
}

.component_form .right_module .fillform_module .list_form > li {
  flex: 1;
  display: flex;
  align-items: center;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li:not(:last-child) {
  border-bottom: 1px solid var(--graycolor2);
}

.component_form .right_module .fillform_module .list_form > li .calendar_block {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .txt_month {
  color: var(--graycolor7);
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  display: block;
  margin: 0 0 5px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .list_calendar {
  display: flex;
  align-items: center;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .list_calendar
  li:not(:last-child) {
  margin: 0 8px 0 0;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .list_calendar
  li
  a {
  border: 1px solid var(--graycolor5);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .list_calendar
  li
  a.active {
  background-color: var(--secondarycolor);
  border-color: var(--secondarycolor);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .list_calendar
  li
  a.active
  .txt_date,
.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .list_calendar
  li
  a.active
  .txt_day {
  color: var(--whitecolor);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .list_calendar
  li
  a
  .txt_date {
  color: var(--graycolor7);
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  display: block;
  margin: 0 0 5px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .calendar_block
  .list_calendar
  li
  a
  .txt_day {
  color: var(--graycolor7);
  font-size: var(--txt_xxs);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
}

.component_form .right_module .fillform_module .list_form > li .slots_block {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .date_location {
  color: var(--darkbluecolor3);
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  display: block;
  margin: 0 0 3px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .txt_slots {
  color: var(--graycolor7);
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  display: block;
  margin: 0 0 5px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .list_slots {
  display: flex;
  flex-wrap: wrap;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .list_slots
  li {
  width: calc(100% / 2);
  margin: 0 0 10px;
  margin: 0 0 10px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .list_slots
  li:not(:nth-child(2n + 2)) {
  padding: 0 10px 0 0;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .list_slots
  li
  a {
  background-color: var(--lightgreencolor3);
  padding: 3px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--txt_xxs);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  color: var(--graycolor8);
  height: 43px;
  margin-right: 1em;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .list_slots
  li
  a.active {
  background-color: var(--secondarycolor);
  color: var(--whitecolor);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .list_slots
  li
  a.disabled {
  background-color: var(--graycolor9);
  color: var(--graycolor5);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .details_block {
  margin: 3px 0 0;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .details_block
  .link_list {
  color: var(--primarycolor);
  font-size: var(--fs16);
  font-family: var(--body-font);
  margin: 0 0 4px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .slots_block
  .details_block
  .txt_list {
  color: var(--subhead_color);
  font-size: var(--txt_xxxxs);
  font-family: var(--body-font);
}

.component_form .right_module .fillform_module .list_form > li .field_lbl {
  display: flex;
  height: 100%;
  padding: 20px 50px;
  cursor: pointer;
  position: relative;
  width: 100%;
  align-items: center;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .field_input_radio {
  display: none;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .field_input_radio:checked
  ~ .dot {
  opacity: 1;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .field_input_radio:checked
  ~ .txt {
  color: var(--whitecolor);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .doctor_item {
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .doctor_item
  .doctor_name {
  color: #707070e5;
  font-size: 18px;
}
.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .doctor_item
  .doctor_earliest_appointment {
  display: flex;
  flex-direction: column;
  background-color: #dbeffd;
  color: #6e6e6e;
  z-index: 2;
  padding: 10px;
}
.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .doctor_item
  .doctor_earliest_appointment
  .txt {
  font-size: 15px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .field_input_radio:checked
  ~ .doctor_item {
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .field_input_radio:checked
  ~ .doctor_item
  .doctor_name {
  color: #ffffffe5;
}
.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .field_lbl
  .field_input_radio:checked
  ~ .doctor_item
  .doctor_earliest_appointment {
  display: flex;
  flex-direction: column;
  background-color: #dbeffd;
  color: #6e6e6e;
  z-index: 2;
  padding: 10px;
}

.component_form .right_module .fillform_module .list_form > li .field_lbl .dot {
  position: absolute;
  inset: 0;
  background-color: var(--secondarycolor);
  opacity: 0;
}

.component_form .right_module .fillform_module .list_form > li .field_lbl .txt {
  color: var(--graycolor7);
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  position: relative;
  z-index: 9;
}

.component_form .right_module .fillform_module .list_form > li .form_group {
  position: relative;
  width: 100%;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group:focus-within {
  border: 1px dashed var(--graycolor3);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group.field_lbl_active
  .field_label {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  font-size: var(--txt_xxs);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .counter_block {
  padding: 0 20px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .list_tick_otp {
  display: flex;
  align-items: center;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .list_tick_otp
  .list_otp {
  display: flex;
  align-items: center;
  margin: 5px 20px 0;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .list_tick_otp
  .list_otp
  li:not(:last-child) {
  margin: 0 10px 0 0;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .list_tick_otp
  .list_otp
  li
  .field_otp {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid var(--graycolor2);
  text-align: center;
  color: var(--subhead_color);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .list_tick_otp
  .fa-check {
  color: var(--greencolor1);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .list_tick_otp
  .fa-xmark {
  color: var(--redcolor1);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .field_label {
  width: 100%;
  padding: 40px 20px 0;
  color: var(--graycolor7);
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  z-index: 1;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .field_label
  .light {
  font-weight: var(--font-light);
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .txt_otp {
  color: var(--graycolor7);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  padding: 10px 20px;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .link_remove,
.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .link_otp {
  color: var(--primarycolor);
  font-size: var(--fs18);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  /* padding: 10px 20px; */
  display: block;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .link_otp_disabled {
  color: lightgray !important;
  cursor: not-allowed !important;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .custom_select {
  padding: 10px 20px 10px 0;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .custom_select
  select {
  outline: none;
  padding: 0 30px 0 20px;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  height: 100%;
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  color: var(--graycolor7);
  position: relative;
  z-index: 9;
  background: url(https://cdn3.iconfinder.com/data/icons/arrows-149/512/directional-chevron-down-512.png)
    no-repeat right center;
  background-size: contain;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .field_txtarea {
  padding: 0 20px;
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  color: var(--lightgraycolor6);
  border: 0;
  resize: none;
}

.component_form
  .right_module
  .fillform_module
  .list_form
  > li
  .form_group
  .field_input {
  padding: 0 20px;
  width: 100%;
  height: 100%;
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  color: var(--lightgraycolor6);
  position: relative;
  z-index: 9;
}

.component_form .right_module .fillform_module .btn_block {
  width: 100%;
  padding: 20px;
  border-top: 1px solid var(--graycolor2);
  text-align: left;
  cursor: pointer;
  position: relative;
}

.component_form .right_module .fillform_module .btn_block .btn_add {
  width: 100%;
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 100%;
  text-align: left;
  font-size: var(--txt_lg);
  font-weight: 500;
  color: var(--graycolor7);
  font-family: var(--body-font);
}

.btn_mob_hamburger {
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: block;
  background-color: transparent;
  border: 0;
  line-height: 10px;
}

.btn_mob_hamburger.darkblue span {
  background-color: var(--whitecolor);
}

.btn_mob_hamburger.lightgray span {
  background-color: var(--graycolor3);
}

.btn_mob_hamburger span {
  width: 100%;
  position: relative;
  height: 2px;
  display: inline-block;
}

.hide_div {
  position: absolute;
  display: flex;
  justify-content: right;
  margin-left: 20px;
  margin-top: -8px;
}

#hide_div .backButton {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border: 1px solid #146daf;
  border-radius: 3px;
  opacity: 1;
  padding: 6px 26px;
  color: #146daf;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
  display: block;
}

#hide_div .nextButton {
  background: #146daf 0% 0% no-repeat padding-box;
  border: 1px solid #146daf;
  border-radius: 3px;
  opacity: 1;
  padding: 6px 26px;
  color: #f6f6f6;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
  display: block;
}

.overlay_block {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  display: none;
  background-color: var(--lightgraycolor4);
  /*list information*/
}

.overlay_block .btn_close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--graycolor3);
  font-size: var(--fs50);
}

.overlay_block .inner_overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.overlay_block .list_info.list_darkblue li a {
  color: var(--lightbluecolor9) !important;
}

.overlay_block .list_info.list_darkblue li a.active,
.overlay_block .list_info.list_darkblue li a:hover {
  color: var(--whitecolor) !important;
}

.overlay_block .list_info li:not(:last-child) {
  margin: 0 0 20px;
}

.overlay_block .list_info li a {
  color: var(--graycolor7);
  font-size: var(--txt_lg);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  display: block;
  padding: 10px 20px;
  position: relative;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.overlay_block .list_info li a.active,
.overlay_block .list_info li a:hover {
  color: var(--subhead_color);
}

.overlay_block .list_info li a.active:before,
.overlay_block .list_info li a:hover:before {
  background-color: var(--primarycolor);
}

.overlay_block .list_info li a:before {
  content: "";
  background-color: var(--lightgraycolor5);
  width: 4px;
  height: 34px;
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.embed-youtube {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.embed-youtube img {
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  height: 100%;
  object-fit: cover;
}

.embed-youtube .embed-youtube-play {
  width: 68px;
  height: 48px;
  background-color: #333;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
}

.embed-youtube .embed-youtube-play:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26px;
  border-color: transparent transparent transparent #fff;
}

.embed-youtube img,
.embed-youtube .embed-youtube-play {
  cursor: pointer;
}

.embed-youtube img,
.embed-youtube iframe,
.embed-youtube .embed-youtube-play,
.embed-youtube .embed-youtube-play:before {
  position: absolute;
}

.embed-youtube .embed-youtube-play,
.embed-youtube .embed-youtube-play:before {
  top: 50%;
  left: 50%;
  /*transform: translate3d(-50%, -50%, 0);*/
}

.embed-youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.embed-youtube .embed-youtube-play:hover {
  background-color: #f00;
}

.fullcalendar_block .fc-toolbar {
  /*
  padding: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  */
}

.fullcalendar_block .fc-toolbar.fc-header-toolbar {
  position: relative;
  background: var(--secondarycolor);
}

.fullcalendar_block .fc-toolbar .fc-left {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
}

.fullcalendar_block .fc-toolbar .fc-left h2 {
  font-size: var(--txt_xxs);
  font-family: var(--body-font);
  color: var(--whitecolor);
  font-weight: var(--font-regular);
}

.fc-toolbar-title {
  font-size: 1em !important;
  font-family: var(--body-font);
  color: var(--whitecolor);
  font-weight: var(--font-regular);
}

.fc-header-toolbar {
  margin-bottom: 0em !important;
}

.fc .fc-button-primary {
  background: none !important;
  border: none !important;
}

table.fc-col-header {
  background-color: var(--lightorangecolor5);
}

table.fc-col-header thead tr th {
  color: var(--blackcolor) !important;
  font-size: var(--txt_xxxs);
  /*font-family: var(--body-font);*/
  padding: 10px;
  border: 0;
}

a {
  color: var(--blackcolor);
}

.fc-scrollgrid-sync-inner {
  /* needs to be same precedence */

  padding-right: 1em;
  /* an override! */
}

.fullcalendar_block .fc-toolbar .fc-right .fc-button-group .fc-month-button,
.fullcalendar_block
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-agendaWeek-button,
.fullcalendar_block
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-timelineCustom-button,
.fullcalendar_block .fc-toolbar .fc-right .fc-button-group .fc-agendaDay-button,
.fullcalendar_block .fc-toolbar .fc-right .fc-button-group .fc-today-button {
  display: none;
}

.fullcalendar_block .fc-toolbar .fc-right .fc-button-group .fc-prev-button,
.fullcalendar_block .fc-toolbar .fc-right .fc-button-group .fc-next-button {
  position: absolute;
  background: transparent;
  border: 0;
  color: var(--whitecolor);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fullcalendar_block
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-prev-button
  .fc-icon:after,
.fullcalendar_block
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-next-button
  .fc-icon:after {
  font-size: 150%;
}

.fullcalendar_block .fc-toolbar .fc-right .fc-button-group .fc-prev-button {
  left: 0;
}

.fullcalendar_block .fc-toolbar .fc-right .fc-button-group .fc-next-button {
  right: 0;
}

.fullcalendar_block .fc-view-container .fc-view .fc-head {
  background-color: var(--lightorangecolor5);
}

.fullcalendar_block .fc-view-container .fc-view .fc-head thead tr th {
  color: var(--blackcolor);
  font-size: var(--txt_xxxs);
  font-family: var(--body-font);
  padding: 10px;
  border: 0;
}

.fullcalendar_block .fc-view-container .fc-view .fc-head thead tr td {
  border: 0;
}

.fullcalendar_block .fc-view-container .fc-view .fc-head thead tr td.fc-today {
  background-color: var(--secondarycolor);
  color: var(--whitecolor);
}

.fullcalendar_block .fc-view-container .fc-view .fc-body .fc-scroller {
  height: auto !important;
}

.fullcalendar_block
  .fc-view-container
  .fc-view
  .fc-body
  .fc-scroller
  .fc-widget-content {
  height: auto !important;
}

.fullcalendar_block .fc-view-container .fc-view .fc-body .fc-scroller td {
  border: 0;
}

.fullcalendar_block
  .fc-view-container
  .fc-view
  .fc-body
  .fc-scroller
  td.fc-today {
  background-color: var(--secondarycolor);
  color: var(--whitecolor);
}

.fullcalendar_block .fc-view-container .fc-license-message {
  display: none;
}

.cd-slot-container {
  margin: auto;
  /*
  padding-top: 1em;
  width:80%;
  height:55px;
   */
}

.cd-slots-full {
  /*padding-top: 0.5em;*/
  /*
  background-color: #e5e5f7;
  opacity: 0.6;
  background: repeating-linear-gradient( -45deg, #f74e45, #f74e45 5px, #e5e5f7 5px, #e5e5f7 25px );
  background-color: #E43D40;
  background-color: #ffeddd;
   */
  background-color: #e0e8f0;
  z-index: 10 !important;
  cursor: pointer !important;
  font-size: 15px;
}

.cd-slots-noclinic {
  background-color: rgb(221, 221, 221);
  cursor: pointer !important;
  color: #d1d1d0;
}

.fc-day-disabled {
  background: var(--body_color) !important;
}

.cd-slots-available {
  /*padding-top: 0.5em;*/
  background-color: #c4fec8;
  cursor: pointer !important;
  font-family: var(--cta-font);
  font-weight: var(--font-regular);
  font-size: 15px;
}

.cd-slots-nostyle {
  /*padding-top: 0.5em;*/
  background: var(--body_color) !important;
  cursor: not-allowed !important;
  /*color: var(--lightgraycolor1);*/
  color: #d1d1d0;
  z-index: 10 !important;
}

.cd-slots-default {
  text-align: center;
  line-height: 2em;
  width: 100%;
  height: 100%;
  /*font-size: 1.6em;*/
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding-top: 1em;
  font-family: var(--cta-font);
  font-weight: var(--font-regular);
  font-size: var(--fs14);
}

.left_module_2 {
  align-items: center;
  padding: 30px 30px;
  margin-top: 50px;
  display: flex;
  position: relative;
}

.date-index {
  padding: 1em;
  margin-bottom: 0.3em;
  margin-left: 2em;
  border-color: darkgray;
  /* border-style: solid; */
  pointer-events: none;
}

.date-index-2 {
  padding: 1em;
  margin-bottom: 0.3em;
  font-size: var(--fs11);
  font-family: var(--body-font);
  font-weight: var(--font-regular);
}

.modal-body {
  text-align: center;
  padding: 0em 0 2em 1em !important;
}

.modal-header {
  padding: 0em !important;
  border: none;
}

.modal-footer {
  border: none;
}

/*COMPONENTS*/
/*BELOW CODE FOR HEADER*/
header {
  padding: 15px 0;
  background-color: var(--whitecolor);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  /*border-bottom:1px solid var(--graycolor5);*/
}

header.bg_transparent {
  background-color: transparent;
}

header.header_c {
  padding: 0;
}

header.header_c .top_title {
  background-color: var(--darkbluecolor3);
  padding: 20px;
  text-align: center;
}

header.header_c .top_title .top_headtitle {
  color: var(--whitecolor);
  font-family: var(--heading-font);
  font-weight: var(--font-regular);
  font-size: var(--subheading6);
  margin: 0;
}

header.header_c .inner_header {
  padding: 10px 0;
}

header.header_c .inner_header {
  position: relative;
}

header.header_c .inner_header .topnav {
  display: flex;
}

header.header_c .inner_header .topnav .list_nav {
  align-items: center;
}

header.header_c .inner_header .topnav .list_nav li a {
  color: var(--subhead_color);
  font-family: var(--heading-font);
  font-weight: var(--font-semibold);
  font-size: var(--txt_normal);
}

header.header_c .inner_header .topnav .list_nav li a.btn_primary {
  height: 48px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: var(--primarycolor);
  color: var(--whitecolor);
  padding: 0 20px;
  line-height: 48px;
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  /* font-size: var(--txt_normal); */
}

header .inner_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .inner_header .topnav .list_nav {
  display: flex;
}

header .inner_header .topnav .list_nav li:not(:last-child) {
  margin: 0 30px 0 0;
}

header .inner_header .topnav .list_nav li a {
  color: var(--nav_color);
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: block;
}

header .inner_header .topnav .list_nav li a.active,
header .inner_header .topnav .list_nav li a:hover {
  color: var(--primarycolor);
}

/*BELOW CODE FOR FOOTER*/
footer {
  background-color: var(--darkbluecolor2);
}

footer .foot_row_one {
  padding: 80px 0 60px;
  background-color: var(--darkbluecolor2);
}

footer .foot_row_one .subscribe_content {
  padding: 0 40px;
}

footer .foot_row_one .subscribe_content .subheadtitle {
  font-size: var(--subheading5);
  font-weight: var(--font-medium);
  color: var(--whitecolor);
  line-height: 30px;
  margin: 0 0 5px;
}

footer .foot_row_one .subscribe_content .txt_desc {
  font-size: var(--txt_xl);
  font-weight: var(--font-light);
  color: var(--whitecolor);
  line-height: 30px;
  margin: 0;
  opacity: 0.8;
}

footer .foot_row_one .fillform_subscribe {
  padding: 0 40px;
}

footer .foot_row_one .fillform_subscribe .fillform {
  display: flex;
  align-items: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  height: 60px;
}

footer .foot_row_one .fillform_subscribe .fillform .field_input {
  width: 60%;
  background-color: var(--whitecolor);
  border: 1px solid var(--graycolor3);
  border-right: 0;
  height: 100%;
  padding: 0 20px;
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  color: var(--txt_color);
}

footer .foot_row_one .fillform_subscribe .fillform .field_input::placeholder {
  color: var(--graycolor4);
}

footer .foot_row_one .fillform_subscribe .fillform .btn_subscribe {
  background-color: var(--primarycolor);
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whitecolor);
  font-size: var(--txt_xl);
  font-family: var(--heading-font);
  height: 100%;
  width: calc(100% - 60%);
}

footer .foot_row_two {
  background-color: var(--darkbluecolor2);
}

footer .foot_row_two .inner_foot_row_two {
  padding: 30px 0;
  border-top: 1px solid var(--graycolor2);
}

footer .foot_row_two .inner_foot_row_two .follow_block {
  display: flex;
  align-items: center;
}

footer .foot_row_two .inner_foot_row_two .follow_block .txt {
  color: var(--whitecolor);
  font-size: var(--txt_normal);
  font-family: var(--heading-font);
  display: inline-block;
  margin: 0 10px 0 0;
}

footer .foot_row_two .inner_foot_row_two .follow_block .list_socialmedia {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

footer
  .foot_row_two
  .inner_foot_row_two
  .follow_block
  .list_socialmedia
  li:not(:last-child) {
  margin: 0 10px 0 0;
}

footer .foot_row_two .inner_foot_row_two .list_link {
  display: flex;
  align-items: center;
}

footer .foot_row_two .inner_foot_row_two .list_link li:not(:last-child) {
  margin: 0 40px 0 0;
}

footer .foot_row_two .inner_foot_row_two .list_link li a {
  letter-spacing: 0.42px;
  font-size: var(--txt_xxs);
  font-family: var(--heading-font);
  color: var(--whitecolor);
  opacity: 0.7;
}

footer .foot_row_three {
  background-color: var(--lightwhitecolor1);
  padding: 30px 0;
  text-align: center;
}

footer .foot_row_three .txt {
  color: var(--txt_color);
  font-size: var(--txt_xxs);
  font-weight: var(--font-regular);
  font-family: var(--body-font);
  letter-spacing: 0.42px;
  line-height: 20px;
  margin: 0;
}

.custom_modal {
  z-index: 9999;
}

.custom_modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.custom_modal .custom_big_width {
  max-width: 900px;
}

.custom_modal .custom_big_width .custom_modalcontent {
  height: 600px;
}

.custom_modal .custom_big_width .custom_modalcontent iframe {
  /*height: 500px;*/
}

.custom_modal .video_frame {
  height: 100%;
}

.custom_modal .custom_md .custom_headtitle {
  font-size: var(--fs24);
  font-weight: var(--font-bold);
  font-family: var(--heading-font);
  color: var(--subhead_color);
  padding-left: 20px;
}

.custom_modal .custom_md .custom_modalcontent {
  height: 600px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 0;
  padding: 15px;
}

.custom_modal .custom_md .custom_modalcontent iframe {
  height: 500px;
}

.custom_modal .custom_md .custom_modalcontent .scroll_auto {
  height: 550px;
  overflow-y: auto;
  /*&::-webkit-scrollbar{
      	width: 12px;
      	background-color: var(--lightgraycolor4);
      }
      &::-webkit-scrollbar-track{
      	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      	border-radius: 10px;
      	background-color: var(--lightgraycolor4);
      }
      &::-webkit-scrollbar-thumb{
      	border-radius: 10px;
      	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      	background-color: var(--primarycolor);
      }*/
}

.custom_modal .custom_md .custom_modalcontent .list_content {
  padding: 20px;
}

.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li:not(:last-child) {
  margin: 0 0 15px;
}

.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .upper_content,
.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .lower_content {
  display: flex;
}

.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .upper_content
  .txt,
.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .lower_content
  .txt {
  text-align: center;
  padding: 10px 20px;
  color: var(--whitecolor);
  font-size: var(--fs14);
  font-family: var(--body-font);
  line-height: 30px;
  display: inline-block;
  font-weight: var(--font-regular);
  height: 50px;
  width: 70px;
}

.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .upper_content
  .txt_desc,
.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .lower_content
  .txt_desc {
  width: 100%;
  padding: 10px 20px;
  color: var(--subhead_color);
  font-size: var(--fs14);
  font-family: var(--body-font);
  line-height: 30px;
  display: inline-block;
  font-weight: var(--font-regular);
}

.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .upper_content
  .bg_orange {
  background-color: var(--primarycolor);
}

.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .upper_content
  .bg_lightred {
  background-color: var(--lightredcolor1);
}

.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .lower_content
  .bg_darkblue {
  background-color: var(--darkbluecolor2);
}

.custom_modal
  .custom_md
  .custom_modalcontent
  .list_content
  li
  .box_face_myths
  .lower_content
  .bg_lightblue {
  background-color: var(--lightbluecolor8);
}

.custom_modal .custom_md .custom_modalcontent .custom_mh .btn_close {
  color: var(--whitecolor);
  opacity: 1;
  filter: brightness(1) invert(1);
  -webkit-filter: brightness(1) invert(1);
  -moz-filter: brightness(1) invert(1);
}

.custom_modal .custom_md .custom_modalcontent .desc {
  color: var(--whitecolor);
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  line-height: 24px;
  margin: 0 0 20px;
}

.bottomMargin {
  margin-top: 30px;
}

.resendCTA {
  /*justify-content: space-between;*/
}

.rightMargin {
  margin-right: 20px;
}

.fc .fc-toolbar-title {
  font-family: "Arial";
  font-weight: var(--font-regular);
  font-size: var(--fs16);
}

.fc .fc-col-header-cell-cushion {
  font-family: var(--cta-font);
  font-weight: var(--font-medium);
  font-size: 12px;
}

.mobileInput {
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  font-size: var(--fs16);
}

.slotNotAvailableContent {
  margin: auto;
  text-align: center;
  display: none;
}

.btn_block .btn_outline {
}

.margin40 {
  margin-top: 40px !important;
}

#counter {
  margin-left: 2em;
  display: none;
  color: var(--bs-orange);
  font-size: 12px;
}

.resendCTA {
  /*margin-right: 150px;*/
  margin-top: 1em !important;
  justify-content: space-between;
}

.close {
  float: right;
  font-size: 2em;
  /*margin: 0rem 0rem -1rem auto;*/
  padding-right: 1rem;
  /*pardding-bottom: 1rem;*/
  background: none;
  color: var(--graycolor7);
  line-height: 1.3em !important;
}

@media (min-width: 1200px) {
  .section_cp .inner_cp #treatment_slider {
    display: none;
  }

  .homepage_content
    .section_diagnoises
    .right_dia_content
    .appt_content
    .box_appt {
    min-height: 420px;
  }
}

@media (min-width: 1400px) {
  .homepage_content
    .section_diagnoises
    .right_dia_content
    .appt_content
    .box_appt {
    min-height: 386px;
  }
  .component_form .right_module .left_patient_reg {
    margin: 20px;
    margin-left: 40px !important;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  #mobile_innercontent {
    display: none;
  }

  header .inner_header .topnav .btn_hamburger {
    display: none;
  }

  .homepage_content .section_journey .mobile_slider_block {
    display: none;
  }
}

@media (max-width: 767px) {
  .bg_button_next_btm {
    position: fixed;
    bottom: 0;
    background: #eaeaea 0% 0% no-repeat padding-box;
    color: #000;
    left: 0;
    display: none;
    flex-direction: row;
    width: 100%;
    padding: 20px;
  }

  .btn_nxt.btn_gray_color {
    flex-direction: row !important;
    justify-content: space-between;
    width: 100%;
    color: #000 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: normal !important;
    font-style: normal !important;
    letter-spacing: 0px !important;
  }

  .horizontal-scrollable > .list_video_slider {
    overflow-x: auto;
    white-space: nowrap;
  }

  .horizontal-scrollable > .list_video_slider > .box_video {
    display: inline-block;
    float: none;
    width: 80%;
  }

  header.header_c .inner_header .topnav .list_nav li a.btn_trans {
    background-color: transparent !important;
    color: var(--subhead_color) !important;
    text-align: left;
    margin-top: auto;
  }

  header.header_c .inner_header .topnav .list_nav li a.btn_trans:hover {
    color: var(--primarycolor) !important;
  }

  .button_next_btm {
    height: 63px !important;
  }
  .button_next_btm.bg_button_next_btm .btn_nxt {
    margin-left: 0;
    margin-top: 0 !important;
  }
}

@media (min-width: 768px) {
  /*BELOW CODE FOMR PAGE*/
  #btn_mob_hamburger {
    display: none;
  }

  #hide_div .backButton {
    display: none;
  }

  #hide_div .nextButton {
    display: none;
  }

  .homepage_content .banner_content .inner_banner #desk_banner_slider {
    display: block;
  }

  .homepage_content .banner_content .inner_banner #mob_banner_slider {
    display: none;
  }

  .homepage_content .mob_diagnosis_slider {
    display: none;
  }

  .homepage_content
    .section_journey
    .speed_meter_block
    .thumbnail
    .img_desktop {
    display: block;
    margin: auto;
  }

  .homepage_content .section_journey .speed_meter_block .thumbnail .img_mobile {
    display: none;
  }

  .inner_content .mobile_list_video {
    display: none;
  }
}

@media (max-width: 1200px) {
  /*BELOW CODE FOR HOMEPAGE*/
  .homepage_content .section_cp.bg_lightgray .inner_cp .box_cp .caption {
    min-height: 265pt;
  }

  .homepage_content .section_cp .inner_cp .box_cp {
    padding: 15px;
    margin: 0 0 20px;
  }

  .homepage_content
    .section_arthritis
    .inner_arthritis
    .gridcol:not(:last-child)
    .box_arthritis
    .divider {
    top: 14%;
  }

  .homepage_content
    .section_arthritis
    .inner_arthritis
    .box_arthritis
    .thumbnail {
    width: 100px;
    height: 100px;
  }

  .homepage_content
    .section_arthritis
    .inner_arthritis
    .box_arthritis
    .caption
    .desc {
    height: 130px;
  }

  .homepage_content .section_experts .experts_content .headtitle {
    margin: 0 0 20px;
  }

  .homepage_content
    .section_experts
    .experts_content
    .list_bullet
    li:not(:last-child) {
    margin: 0 0 10px;
  }

  .homepage_content .section_cp .inner_cp #treatment_slider {
    display: block;
  }

  .homepage_content .section_cp .inner_cp .desktop_treament {
    display: none;
  }

  .homepage_content .section_diagnoises .left_dia_content {
    display: none;
  }

  /*BELOW CODE FOR INNER PAGE*/
  .inner_content {
    overflow-x: hidden;
  }

  .inner_content .title_nav_block {
    margin: 0 0 10px;
  }

  .inner_content .title_nav_block .headtitle {
    font-size: var(--subheading7);
  }

  .inner_content .title_nav_block .list_navsection {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .inner_content .title_nav_block .list_navsection::-webkit-scrollbar {
    display: none;
  }

  .inner_content .title_nav_block .list_navsection li a {
    font-size: var(--txt_xxs);
    line-height: 45px;
    height: 45px;
  }

  .inner_content .section_padding {
    margin: 0 -15px 30px;
    padding: 20px 20px 0;
  }

  .inner_content .section_padding .subheadtitle {
    font-size: var(--subheading7);
  }

  .inner_content .section_padding .video_block .list_video .box_video {
    margin: 0 0 20px;
  }

  .inner_content
    .section_padding
    .video_block
    .list_video
    .box_video.small_height {
    height: 200px;
  }

  .inner_content
    .section_padding
    .video_block
    .list_video
    .box_video.big_height {
    height: 300px;
  }

  .inner_content .section_padding .custom_tabcontent {
    padding: 10px 0;
  }

  .inner_content .section_padding .customnav {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
  }

  .inner_content .section_padding .customnav::-webkit-scrollbar {
    display: none;
  }

  .inner_content .section_padding .customnav li:not(:last-child) {
    margin: 0 20px 0 0;
  }

  .inner_content .section_padding .customnav li a {
    font-size: var(--txt_normal) !important;
  }

  /*BELOW CODE FOR FOOTER*/
  footer .foot_row_one .subscribe_content,
  footer .foot_row_one .fillform_subscribe {
    padding: 0;
  }

  footer .foot_row_two .follow_block .list_socialmedia li:not(:last-child) {
    margin: 0;
  }

  footer .foot_row_two .list_link li:not(:last-child) {
    margin: 0 10px 0 0;
  }
}

@media (max-width: 991px) {
  .ba_doctor_content_container .clinic-doctors-list {
    height: 100%;
    max-height: 600px;
    padding: 10px 0px;
  }

  .clinic_list_banner {
    height: 138px;
  }

  .clinic_list_banner .clinic_list_banner_title {
    font: 23px / 24px Fira Sans;
  }
  .clinic_list_banner .clinic_list_banner_title_ic4 {
    font-weight: 900;
    font-family: "Archivo Black", sans-serif;
    font-size: 28px;
  }

  .clinic_list_banner .clinic_list_container.container {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    height: 100%;
  }

  .clinic_list_banner .clinic_list_container .clinic_list {
    overflow: auto;
    width: 100%;
    margin: 0 20px;
    padding: 0;
  }

  .clinic_page_content_container
    .clinic-doctors-list-container
    .clinic-doctors-list
    .clinic-doctors-list-item {
    margin: 10px 0px;
  }

  .clinic_page_content_container
    .clinic-doctors-list-container
    .clinic-doctors-list-title1,
  .clinic_page_content_container
    .clinic-doctors-list-container
    .clinic-doctors-list-title2,
  .clinic_page_content_container
    .clinic-doctors-list-container
    .clinic-doctors-list-title3 {
    padding: 0;
    display: block;
    margin-bottom: 6px;
  }

  .clinic_list_banner .clinic_list .clinic_list_item {
    padding: 10px 15px;
  }

  .mobile_innercontent {
    margin-top: 50px;
  }

  .mobile_innercontent .accordion .accordion-item {
    border-color: var(--whitecolor);
    border-bottom: 1px solid var(--graycolor5);
  }

  .mobile_innercontent
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    background-color: var(--whitecolor);
    color: var(--blackcolor);
    font-size: var(--fs22);
    font-family: var(--body-font);
    color: var(--primarycolor);
    box-shadow: initial;
  }

  .mobile_innercontent
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed {
    color: var(--blackcolor);
  }

  .mobile_innercontent
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button:after {
    /*
          background-image: initial;
          content: "\f063" !important;
          font-family: 'Font Awesome 5 Free';
          font-weight: bold;
          color: var(--primarycolor); */
  }

  .web_innercontent {
    display: none;
  }

  .body_scroll {
    transform: initial !important;
  }

  /*BELOW CODE FOR HEADER*/
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  /*BELOW CODE FOR HOMEPAGE*/
  .clinic_list_banner {
    margin: 175px 0 0;
  }

  .clinic_list_banner.clinic_page {
    margin: 102px 0 0;
  }

  .clinic_list_banner .clinic_list_container {
    overflow: auto;
  }

  .main_content .section_content {
    height: auto !important;
  }

  .main_content .section_content:nth-child(1) {
    height: auto !important;
  }

  .main_content.spacing_reduce {
    margin: 80px 0 0;
  }

  .main_content.book_app_spacing_reduce {
    margin-top: 0 !important;
    height: calc(100vh - 164px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepage_content
    .banner_content.banner_content_new
    .inner_banner
    .bigheadtitle {
    font-size: var(--fs24);
    line-height: 36px;
  }

  .homepage_content
    .banner_content
    .inner_banner
    .banner-slider
    .banner-middle-content
    .myth_fact_block
    .inner_mf
    .txt_legend {
    font-size: var(--txt_xxs);
  }

  .homepage_content
    .banner_content
    .inner_banner
    .banner-slider
    .banner-middle-content
    .myth_fact_block
    .inner_mf
    .txt_summary {
    font-size: var(--txt_lg);
  }

  .homepage_content .section_title {
    padding: 15px;
  }

  .homepage_content .section_title .headtitle {
    font-size: var(--txt_xxs);
  }

  .homepage_content .section_suffering .inner_suff .left_stuff_content {
    display: none;
  }

  .homepage_content
    .section_suffering
    .inner_suff
    .right_suff_content
    .title_block
    .headtitle {
    font-size: var(--subheading7);
    line-height: 32px;
  }

  .homepage_content
    .section_suffering
    .inner_suff
    .right_suff_content
    .list_suff {
    margin: 0 auto 30px;
  }

  .homepage_content .section_diagnoises .left_dia_content {
    display: none;
  }

  .homepage_content
    .section_diagnoises
    .right_dia_content
    .appt_content
    .box_appt {
    height: 450px;
  }

  .homepage_content
    .section_diagnoises
    .right_dia_content
    .appt_content
    .subtitle {
    font-size: var(--txt_lg);
  }

  .homepage_content .section_sc .title_block .headtitle {
    font-size: var(--subheading7);
    line-height: 26px;
  }

  .homepage_content .section_sc .inner_sc .box_sc .subtitle {
    font-size: var(--txt_xxs);
    margin: 0 0 10px;
    width: 100%;
    text-align: center;
    order: 1;
  }

  .homepage_content .section_sc .inner_sc .box_sc .thumb {
    order: 2;
  }

  .homepage_content
    .section_diagnoises
    .right_dia_content
    .title_block
    .headtitle {
    font-size: var(--subheading7);
    line-height: 26px;
  }

  .homepage_content .section_cp.bg_lightgray .inner_cp .box_cp .caption {
    min-height: 265pt;
  }

  .homepage_content .section_cp.bg_lightgray .inner_cp .box_cp .caption .desc {
    min-height: 130px;
  }

  .homepage_content .section_cp .title_block {
    margin: 0 0 20px;
  }

  .homepage_content .section_cp .title_block .headtitle {
    margin: 0;
    font-size: var(--subheading7);
    line-height: 26px;
  }

  .homepage_content .section_cp .title_block .desc {
    font-size: var(--txt_xxs);
    line-height: 24px;
    font-weight: var(--font-medium);
  }

  .homepage_content .section_cp .title_block .desc:not(:last-child) {
    margin: 0 0 10px;
  }

  .homepage_content .section_cp .inner_cp .box_cp .caption {
    min-height: 230pt;
  }

  .homepage_content .section_cp .inner_cp .box_cp .caption .subheadtitle {
    font-size: var(--txt_lg);
  }

  .homepage_content .section_cp .inner_cp .box_cp .caption .desc {
    font-size: var(--txt_xxs);
  }

  .homepage_content .section_journey .title_block .headtitle {
    font-size: var(--subheading7);
    line-height: 26px;
  }

  .homepage_content .section_journey .speed_meter_block .subtitle {
    font-size: var(--txt_normal);
    line-height: 22px;
  }

  .homepage_content .section_journey .desktop_slider_block {
    display: none;
  }

  .homepage_content .section_arthritis .title_block {
    margin: 0 0 20px;
  }

  .homepage_content .section_arthritis .title_block .headtitle {
    font-size: var(--subheading7);
    line-height: 26px;
  }

  .homepage_content
    .section_arthritis
    .inner_arthritis
    .box_arthritis
    .caption
    .subheadtitle {
    font-size: var(--txt_lg);
  }

  .homepage_content
    .section_arthritis
    .inner_arthritis
    .box_arthritis
    .caption
    .desc {
    min-height: 100px;
    font-size: var(--txt_xxs);
  }

  .homepage_content
    .section_arthritis
    .list_arthritis
    li
    .box_arthritis
    .caption
    .subheadtitle {
    font-size: var(--txt_lg);
    line-height: normal;
    margin: 0 0 10px;
  }

  .homepage_content
    .section_arthritis
    .list_arthritis
    li
    .box_arthritis
    .caption
    .desc {
    font-size: var(--txt_xxs);
    line-height: 24px;
    margin: 0 0 5px;
  }

  .homepage_content
    .section_arthritis
    .list_arthritis
    li
    .box_arthritis
    .caption
    .link_readmore {
    font-size: var(--txt_lg);
    line-height: normal;
  }

  .homepage_content .section_experts .mob_frame {
    text-align: center;
  }

  .homepage_content .section_experts .experts_content {
    padding: 0 30px 0 0;
  }

  .homepage_content .section_experts .experts_content .headtitle {
    font-size: var(--subheading7);
    line-height: 26px;
    margin: 0 0 20px;
  }

  .homepage_content
    .section_experts
    .experts_content
    .list_bullet
    li:not(:last-child) {
    margin: 0 0 10px;
  }

  .homepage_content .section_experts .experts_content .list_bullet li .dot {
    width: 5px;
    height: 5px;
  }

  .homepage_content .section_experts .experts_content .list_bullet li .txt {
    font-size: var(--txt_xxs);
  }

  .homepage_content .section_experts .experts_content .download_app_block {
    margin: 0 0 25px;
  }

  .homepage_content
    .section_experts
    .experts_content
    .download_app_block
    .subtitle {
    font-size: var(--subheading8);
  }

  .homepage_content .section_fp .title_block .headtitle {
    font-size: var(--subheading7);
    line-height: 26px;
  }

  /*BELOW CODE FOR INNER PAGE*/
  .inner_content .custom_nav .nav-item .nav-link {
    font-size: var(--fs16);
  }

  .inner_content .title_nav_block {
    margin: 0;
  }

  .inner_content .title_nav_block .headtitle {
    margin: 0 0 10px;
  }

  .inner_content .section_padding .customnav .nav-item:not(:last-child) {
    margin: 0 10px 0 0;
  }

  .inner_content .section_padding .customnav .nav-item .nav-link.active:after {
    bottom: 0;
  }

  .inner_content
    .section_padding
    .custom_tabcontent
    .custom_tabpane
    .vertical_tab_content
    .nav
    .nav-link {
    font-size: var(--txt_xxs);
  }

  .inner_content
    .section_padding
    .custom_tabcontent
    .custom_tabpane
    .summary_block
    .desc {
    font-size: var(--txt_xxs);
    line-height: 28px;
  }

  /* CODE FOR CLINIC LANDING PAGE */

  .clinic_page_content_container
    .clinic-doctors-list-container
    .clinic-doctors-list {
    flex-direction: column;
    align-items: center;
  }

  /*BELOW CODE FOR BOOK APPOINTMENT*/
  /*EDIT PAGE*/
  .ba_wrapper_content {
    margin: 0;
  }

  .ba_wrapper_content .information_block .left_info {
    justify-content: flex-start;
    padding: 0;
  }

  .ba_wrapper_content .inner_body {
    margin: 0;
  }

  /*BELOW CODE FOR FOOTER*/
  footer .foot_row_two .inner_foot_row_two .logo {
    margin: 0 0 10px;
    display: flex;
    justify-content: center;
  }

  .button_next_btm {
    height: 114px;
  }

  .button_next_btm.bg_button_next_btm .btn_nxt {
    margin-top: 30px;
  }

  .component_form .left_module .titleform {
    font-size: var(--fs22);
    line-height: 30px;
  }

  .component_form .left_module_2 .titleform {
    font-size: 29px;
    line-height: 30px;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .calendar_block
    .list_calendar {
    overflow-x: auto;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .slots_block
    .txt_slots {
    margin: 0 0 5px;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .slots_block
    .list_slots
    li {
    margin: 0;
    width: 50%;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .slots_block
    .list_slots
    li:not(:nth-child(2n + 2)) {
    padding: 5px;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .slots_block
    .list_slots
    li
    a {
    padding: 5px;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .slots_block
    .details_block {
    align-items: center;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .custom_select.border_b {
    border-bottom: 1px solid var(--graycolor2);
  }

  .component_form .right_module .fillform_module .list_form > li .field_lbl {
    padding: 20px;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .field_lbl
    .txt {
    font-size: var(--txt_normal);
  }

  .btn_primary {
    font-size: var(--txt_xxs);
    height: 35px;
    line-height: 35px;
    margin: 10px auto;
    font-family: var(--cta-font);
  }

  .backButton2 {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border: 1px solid grey;
    border-radius: 3px;
    opacity: 1;
    padding: 2px 20px;
    color: black;
    text-align: center;
    height: 35px;
    letter-spacing: 0px;
  }
}

@media (max-width: 786px) {
  /*BELOW CODE FOR HOMEPAGE*/
  .homepage_content .section_cp .inner_cp .box_cp .caption {
    min-height: 210pt;
  }
}

@media (max-width: 767px) {
  /*BELOW CODE FOR HEADER*/
  header.header_c .top_title {
    padding: 5px;
  }

  header.header_c .top_title .top_headtitle {
    font-size: var(--txt_xxxxs);
  }

  header.header_c .inner_header .topnav .list_nav {
    top: 80px;
    background-color: var(--whitecolor);
  }

  header .inner_header .logo img {
    max-width: 120px;
  }

  header .inner_header .topnav .btn_hamburger {
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    background-color: var(--whitecolor);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  header .inner_header .topnav .btn_hamburger.active span {
    background-color: transparent;
  }

  header .inner_header .topnav .btn_hamburger.active span:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -moz-transform-origin: 3px 5px;
    -o-transform-origin: 3px 5px;
    -ms-transform-origin: 3px 5px;
    -webkit-transform-origin: 3px 5px;
    transform-origin: 3px 5px;
  }

  header .inner_header .topnav .btn_hamburger.active span:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -moz-transform-origin: 4px -1px;
    -o-transform-origin: 4px -1px;
    -ms-transform-origin: 4px -1px;
    -webkit-transform-origin: 4px -1px;
    transform-origin: 4px -1px;
  }

  header .inner_header .topnav .btn_hamburger span {
    position: relative;
    width: 80%;
    height: 3px;
    background-color: var(--graycolor6);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    display: block;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }

  header .inner_header .topnav .btn_hamburger span:before,
  header .inner_header .topnav .btn_hamburger span:after {
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--graycolor6);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }

  header .inner_header .topnav .btn_hamburger span:before {
    top: -7px;
  }

  header .inner_header .topnav .btn_hamburger span:after {
    top: 7px;
  }

  header .inner_header .topnav .list_nav {
    display: none;
    position: absolute;
    top: 92px;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #0a416a;
  }

  header .inner_header .topnav .list_nav li a {
    color: var(--whitecolor);
    padding: 10px 20px;
    display: block;
  }

  /*BELOW CODE FOR MAIN CONTENT*/
  .clinic_list_banner {
    margin: 104px 0 0;
    /*BELOW CODE FOR HOMEPAGE*/
    /*BELOW CODE FOR INNER PAGE*/
    /*BELOW CODE FOR BOOK APPOINTMENT*/
    /*EDIT PAGE*/
  }

  .clinic_list_banner.clinic_page {
    margin: 85px 0 0;
  }

  .clinic_list_banner .clinic_list_container {
    overflow: auto;
  }

  .main_content .section_content {
    padding: 30px 0;
  }

  .main_content
    .homepage_content
    .banner_content.banner_content_new
    .inner_banner
    .left_banner {
    text-align: center;
  }

  .main_content
    .homepage_content
    .banner_content.banner_content_new
    .inner_banner
    .left_banner
    .bigheadtitle {
    font-size: var(--subheading8);
    line-height: 36px;
  }

  .main_content .homepage_content .banner_content .inner_banner {
    padding: 0;
  }

  .main_content
    .homepage_content
    .banner_content
    .inner_banner
    .banner-slider
    .banner-middle-content
    .myth_fact_block
    .inner_mf
    .txt_legend {
    font-size: var(--txt_xxxs);
  }

  .main_content
    .homepage_content
    .banner_content
    .inner_banner
    .banner-slider
    .banner-middle-content
    .myth_fact_block
    .inner_mf
    .txt_summary {
    font-size: var(--txt_xxs);
    padding: 10px;
  }

  .main_content
    .homepage_content
    .banner_content
    .inner_banner
    #desk_banner_slider {
    display: none;
  }

  .main_content
    .homepage_content
    .banner_content
    .inner_banner
    #mob_banner_slider {
    display: block;
  }

  .main_content
    .homepage_content
    .section_suffering
    .inner_suff
    .right_suff_content
    .title_block {
    margin: 0 0 20px;
  }

  .main_content
    .homepage_content
    .section_suffering
    .inner_suff
    .right_suff_content
    .list_suff {
    flex-direction: column;
  }

  .main_content
    .homepage_content
    .section_suffering
    .inner_suff
    .right_suff_content
    .list_suff
    li {
    width: 100%;
    text-align: left;
  }

  .main_content
    .homepage_content
    .section_suffering
    .inner_suff
    .right_suff_content
    .list_suff
    li:not(:last-child) {
    margin: 0 0 30px;
  }

  .main_content .homepage_content .section_diagnoises {
    background-image: initial;
  }

  .main_content
    .homepage_content
    .section_diagnoises
    .mob_diagnosis_slider
    .right_dia_content
    .appt_content
    .box_appt {
    height: 430px;
    width: 254px;
    margin-left: 20px;
  }

  #mob_diagnosis_slider.owl-theme .owl-nav {
    display: none;
  }

  #mob_diagnosis_slider.owl-theme .owl-dots {
    display: none;
  }

  #treatment_slider.owl-theme .owl-dots {
    margin-top: -46px;
  }

  #treatment_slider.owl-theme .owl-dots .owl-dot span {
    background: var(--primarycolor);
    margin: 5px;
  }

  #treatment_slider.owl-theme .owl-dots .owl-dot.active span {
    background: var(--secondarycolor);
  }

  .owl-carousel .owl-item img {
    width: auto;
  }

  .main_content
    .homepage_content
    .section_diagnoises
    .mob_diagnosis_slider
    .right_dia_content
    .appt_content
    .slick-list {
    padding: 0 !important;
  }

  .main_content
    .homepage_content
    .section_diagnoises
    .mob_diagnosis_slider
    .right_dia_content
    .appt_content
    .slick-slide {
    padding: 0 20px;
  }

  .main_content
    .homepage_content
    .section_diagnoises
    .right_dia_content
    .title_block {
    margin: 0 0 20px;
  }

  .main_content .homepage_content .section_diagnoises .right_dia_content .row {
    display: none;
  }

  .main_content
    .homepage_content
    .section_diagnoises
    .right_dia_content
    .appt_content
    .subtitle {
    margin: 0 0 10px;
  }

  .main_content
    .homepage_content
    .section_diagnoises
    .right_dia_content
    .appt_content
    .btn_block.my-5 {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
  }

  .main_content
    .homepage_content
    .section_diagnoises
    .right_dia_content
    .appt_content
    .gridcol {
    scroll-snap-align: center;
  }

  .main_content
    .homepage_content
    .section_diagnoises
    .right_dia_content
    .appt_content
    .gridcol:not(:last-child) {
    margin: 0 0 30px;
  }

  .main_content .homepage_content .section_journey {
    height: 800px !important;
  }

  .main_content
    .homepage_content
    .section_journey
    .speed_meter_block
    .subtitle {
    font-size: var(--txt_normal);
    padding: 0 30px;
  }

  .main_content
    .homepage_content
    .section_journey
    .speed_meter_block
    .thumbnail
    .img_desktop {
    display: none;
  }

  .main_content
    .homepage_content
    .section_journey
    .speed_meter_block
    .thumbnail
    .img_mobile {
    display: block;
    margin: auto;
  }

  .main_content
    .homepage_content
    .section_journey
    .speed_meter_block
    .gridcol:not(:last-child) {
    margin: 0 0 15px;
  }

  .main_content
    .homepage_content
    .section_journey
    .speed_meter_block
    .gridcol
    .box_speedmeter {
    padding: 0 40px;
  }

  .main_content
    .homepage_content
    .section_journey
    .speed_meter_block
    .gridcol
    .box_speedmeter
    .list_bullet
    li:not(:last-child) {
    margin: 0 0 5px;
  }

  .main_content
    .homepage_content
    .section_cp.bg_lightgray
    .inner_cp
    .box_cp
    .caption {
    min-height: 230pt;
  }

  .main_content .homepage_content .section_cp .inner_cp .row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .main_content
    .homepage_content
    .section_cp
    .inner_cp
    .row::-webkit-scrollbar {
    display: none;
  }

  .main_content .homepage_content .section_cp .inner_cp .box_cp .caption .desc {
    margin: 0;
  }

  .main_content
    .homepage_content
    .section_arthritis
    .inner_arthritis
    .box_arthritis {
    padding: 0 15px;
    display: flex;
    align-items: flex-start;
  }

  .main_content
    .homepage_content
    .section_arthritis
    .inner_arthritis
    .box_arthritis
    .caption {
    width: calc(100% - 100px);
    padding: 0 10px;
  }

  .main_content
    .homepage_content
    .section_arthritis
    .inner_arthritis
    .box_arthritis
    .caption
    .desc {
    min-height: auto;
    height: auto;
  }

  .main_content .homepage_content .section_arthritis .inner_arthritis .gridcol {
    padding: 0 0 50px;
  }

  .main_content
    .homepage_content
    .section_arthritis
    .inner_arthritis
    .gridcol:not(:last-child)
    .box_arthritis
    .divider {
    width: 5px;
    height: 100%;
    left: 12%;
    top: 34%;
  }

  .main_content
    .homepage_content
    .section_arthritis
    .list_arthritis
    li:not(:last-child)
    .divider_vertical {
    width: 2px;
    height: 100%;
    background-color: var(--secondarycolor);
    display: block;
    position: absolute;
    top: 0;
    left: 15%;
  }

  .main_content
    .homepage_content
    .section_arthritis
    .list_arthritis
    li
    .box_arthritis
    .caption {
    text-align: center;
  }

  .main_content .homepage_content .section_sc .row {
    align-items: center;
  }

  .main_content .homepage_content .section_sc .inner_sc .box_sc .thumb {
    width: 80px;
    height: 80px;
  }

  .main_content .homepage_content .section_sc .right_sc_content {
    display: none;
  }

  .main_content .inner_content .custom_nav {
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .main_content .inner_content .custom_nav::-webkit-scrollbar {
    display: none;
  }

  .main_content .inner_content .custom_nav .nav-item {
    width: 100%;
  }

  .main_content .inner_content .custom_nav .nav-item .nav-link {
    font-size: var(--txt_lg);
  }

  .main_content .inner_content .title_nav_block .list_navsection {
    width: 100%;
  }

  .main_content
    .inner_content
    .section_padding
    .custom_tabcontent
    .custom_tabpane
    .vertical_tab_content
    .nav {
    display: flex;
    flex-wrap: nowrap;
    flex: auto;
    overflow-x: auto;
    white-space: nowrap;
    flex-direction: row !important;
    margin: 0 0 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
  }

  .main_content
    .inner_content
    .section_padding
    .custom_tabcontent
    .custom_tabpane
    .vertical_tab_content
    .nav::-webkit-scrollbar {
    display: none;
  }

  .main_content
    .inner_content
    .section_padding
    .custom_tabcontent
    .custom_tabpane
    .vertical_tab_content
    .nav
    .nav-link {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }

  .main_content
    .inner_content
    .section_padding
    .custom_tabcontent
    .custom_tabpane
    .vertical_tab_content
    .nav
    .nav-link:not(:last-child) {
    margin: 0;
  }

  .main_content
    .inner_content
    .section_padding
    .custom_tabcontent
    .custom_tabpane
    .summary_block {
    margin: 0 0 20px;
  }

  .main_content
    .inner_content
    .section_padding
    .custom_tabcontent
    .custom_tabpane
    .summary_block
    .desc {
    margin: 0 0 15px;
  }

  .main_content .inner_content .section_padding .video_block {
    margin: 0 0 10px;
  }

  .main_content .inner_content .section_padding .video_block .maintitle {
    font-size: var(--txt_normal);
  }

  .main_content
    .inner_content
    .section_padding
    .video_block
    .desktop_list_video {
    display: none;
  }

  .main_content
    .inner_content
    .section_padding
    .video_block
    .mobile_list_video {
    display: block;
  }

  .main_content
    .inner_content
    .section_padding
    .video_block
    .mobile_list_video
    .list_video_slider
    .slick-list {
    padding: 0 !important;
  }

  .main_content
    .inner_content
    .section_padding
    .video_block
    .mobile_list_video
    .list_video_slider
    .slick-list
    .slick-track
    .box_video {
    margin: 0 10px;
  }

  .main_content
    .inner_content
    .section_padding
    .video_block
    .list_video
    .box_video {
    width: 252px !important;
  }

  .main_content
    .inner_content
    .section_padding
    .video_block
    .list_video
    .box_video.small_height,
  .main_content
    .inner_content
    .section_padding
    .video_block
    .list_video
    .box_video.big_height {
    height: 200px;
  }

  .main_content .ba_wrapper_content {
    height: calc(100vh - 145px);
    margin-top: -55px;
  }

  .main_content .ba_wrapper_content .information_block {
    padding: 0;
    height: calc(100vh - 310px);
    display: flex;
    margin-left: 0 !important;
  }

  .main_content .ba_wrapper_content .information_block .left_info .subtitle {
    font-size: var(--subheading8);
  }

  .main_content .ba_wrapper_content .information_block .left_info .list_info {
    display: none;
  }

  .main_content .ba_wrapper_content .inner_body {
    padding: 10px;
    margin-bottom: -45px;
  }

  .main_content .ba_wrapper_content .inner_body .title_block {
    margin: 0 0 10px;
  }

  .main_content .ba_wrapper_content .inner_body .title_block .subtitle {
    font-size: var(--fs24);
  }

  .main_content
    .ba_wrapper_content
    .inner_body
    .panel_wrapper
    .panel_block
    .panel_title {
    padding: 15px;
  }

  .main_content
    .ba_wrapper_content
    .inner_body
    .panel_wrapper
    .panel_block
    .panel_body
    .list_panel
    li
    .box_panel {
    padding: 10px 15px;
  }

  .main_content .ba_wrapper_content .inner_body .panel_wrapper .checkbox_block {
    margin: auto;
    position: sticky;
    bottom: 0px;
    background: white;
    padding-top: 22px;
    box-shadow: 0px -6px 10px #00000029;
    width: 353px;
    padding: 15px;
    padding-bottom: 0;
  }

  .main_content
    .ba_wrapper_content
    .inner_body
    .panel_wrapper
    .checkbox_block
    label
    .caption
    .txt {
    margin: 0 0 10px;
  }

  .component_form .left_module {
    padding: 10px;
  }

  .component_form .left_module .desc {
    margin: 0;
  }

  .component_form .right_module .fillform_module {
    width: calc(100vw - 30px);
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    li
    .slots_block
    .list_slots
    li {
    padding: 5px;
    margin: 0 0 5px;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    li
    .slots_block
    .list_slots
    li
    a {
    padding: 5px;
    font-size: var(--txt_xxs);
  }

  .component_form .right_module .fillform_module .list_form li .form_group {
    padding: 10px 0;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    li
    .form_group
    .custom_select {
    padding: 20px 20px 20px 0;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    li
    .form_group
    .field_label {
    padding: 10px 20px 0;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    li
    .form_group
    .field_label,
  .component_form
    .right_module
    .fillform_module
    .list_form
    li
    .form_group
    .field_input {
    font-size: var(--txt_normal);
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    li
    .form_group.field_lbl_active
    .field_label {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  .btn_back {
    top: 40px;
    right: 20px;
    left: auto;
    /*
    -webkit-transform: translateY(auto);
    -moz-transform: translateY(auto);
    -ms-transform: translateY(auto);
    transform: translateY(auto);
     */
    display: none;
  }

  .ctaBar {
    position: absolute !important;
    display: flex !important;
    justify-content: space-between !important;
  }

  .btnDisabled {
    color: darkgrey !important;
    border-color: darkgrey !important;
    cursor: not-allowed;
    background: none !important;
  }

  .button_next_btm {
    padding: 20px;
  }

  .button_next_btm.bg_gray_button_next_btm {
    background-color: var(--lightgraycolor1);
  }

  .button_next_btm .btn_nxt {
    flex-direction: row;
    justify-content: space-between;
  }

  /*BELOW CODE FOR FOOTER*/
  footer .foot_row_one {
    padding: 30px 0;
  }

  footer .foot_row_one .subscribe_content {
    margin: 0 0 25px;
  }

  footer .foot_row_one .subscribe_content .subheadtitle {
    font-size: var(--subheading7);
    margin: 0;
  }

  footer .foot_row_one .subscribe_content .txt_desc {
    font-size: var(--txt_xxxs);
  }

  footer .foot_row_one .fillform_subscribe .fillform {
    height: 50px;
  }

  footer .foot_row_one .fillform_subscribe .fillform .btn_subscribe {
    font-size: var(--txt_normal);
  }

  footer .foot_row_two .inner_foot_row_two .follow_block {
    flex-direction: column;
  }

  footer
    .foot_row_two
    .inner_foot_row_two
    .follow_block
    .list_socialmedia
    li:not(:last-child) {
    margin: 0;
  }

  footer .foot_row_two .inner_foot_row_two .list_link {
    border-top: 1px solid var(--graycolor2);
    padding: 30px 0 0;
    margin: 30px 0 0;
    justify-content: center;
  }

  footer .foot_row_two .inner_foot_row_two .list_link li a {
    font-size: var(--txt_xxxs);
    letter-spacing: 0.36px;
  }

  footer .foot_row_three {
    padding: 15px 0;
  }

  footer .foot_row_three .txt {
    font-size: var(--txt_xxxxs);
    letter-spacing: 0.39px;
  }

  .custom_modal
    .custom_md
    .custom_modalcontent
    .list_content
    li
    .box_face_myths
    .upper_content,
  .custom_modal
    .custom_md
    .custom_modalcontent
    .list_content
    li
    .box_face_myths
    .lower_content {
    flex-direction: column;
  }

  .custom_modal
    .custom_md
    .custom_modalcontent
    .list_content
    li
    .box_face_myths
    .upper_content
    .txt,
  .custom_modal
    .custom_md
    .custom_modalcontent
    .list_content
    li
    .box_face_myths
    .lower_content
    .txt {
    width: 100%;
  }

  .custom_modal .custom_md .custom_modalcontent .scroll_auto {
    padding: 0;
  }
}

@media (max-width: 575px) {
  /*BELOW CODE FOR HOMEPAGE*/
  .homepage_content .section_cp .inner_cp .box_cp .caption {
    min-height: auto;
  }

  .homepage_content .section_cp .inner_cp .box_cp .caption .subheadtitle {
    margin: 60px 0 20px;
  }

  .homepage_content
    .section_arthritis
    .inner_arthritis
    .gridcol:not(:last-child)
    .box_arthritis
    .divider {
    left: 12%;
  }
}

@media (max-width: 460px) {
  /*BELOW CODE FOR HOMEPAGE*/
  .homepage_content
    .section_arthritis
    .inner_arthritis
    .gridcol:not(:last-child)
    .box_arthritis
    .divider {
    left: 15% !important;
  }
}

@media (max-width: 400px) {
  /*BELOW CODE FOR HOMEPAGE*/
  .homepage_content
    .section_arthritis
    .inner_arthritis
    .gridcol:not(:last-child)
    .box_arthritis
    .divider {
    left: 17% !important;
  }

  .horizontal-scrollable > .list_video_slider {
    overflow-x: auto;
    white-space: nowrap;
  }

  .horizontal-scrollable > .list_video_slider > .box_video {
    display: inline-block;
    float: none;
    width: 80%;
  }
}

@media (max-width: 380px) {
  /*BELOW CODE FOR HOMEPAGE*/
  .homepage_content
    .section_arthritis
    .inner_arthritis
    .gridcol:not(:last-child)
    .box_arthritis
    .divider {
    left: 17% !important;
  }

  .horizontal-scrollable > .list_video_slider {
    overflow-x: auto;
    white-space: nowrap;
  }

  .horizontal-scrollable > .list_video_slider > .box_video {
    display: inline-block;
    float: none;
    width: 80%;
  }
}

@media (max-width: 340px) {
  /*BELOW CODE FOR HOMEPAGE*/
  .homepage_content
    .section_arthritis
    .inner_arthritis
    .gridcol:not(:last-child)
    .box_arthritis
    .divider {
    left: 19% !important;
  }

  .horizontal-scrollable > .list_video_slider {
    overflow-x: auto;
    white-space: nowrap;
  }

  .horizontal-scrollable > .list_video_slider > .box_video {
    display: inline-block;
    float: none;
    width: 80%;
  }
}

/* top bottom button responsive */

.main_new_content {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.main_new_content .inner_content {
  height: 100vh;
  width: 100%;
}

.main_new_content .inner_content .information_block {
  /* height: 100%; */
  margin-left: 32px;
}

.main_new_content .inner_content .information_block .btn_back.btn_gray_color {
  /* left: 100px;
  width: 100%;
  top: 0;
  margin-top: 0;
  margin-left: 0;
  transform: initial;
  text-align: center;
  position: absolute; */
}

.main_new_content
  .inner_content
  .information_block
  .button_next_btm.bg_gray_button_next_btm {
  /* bottom: -30px;
  padding: 0;
  position: absolute; */
}

.container-fluid .information_block .btn_nxt {
  cursor: pointer;
  font-size: var(--txt_normal);
  font-family: var(--body-font);
  font-weight: var(--font-light);
  text-align: center;
  color: var(--graycolor7);

  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 10px;
}

.ba_wrapper_content {
  height: calc(100vh - 200px);
  margin: 0;
}

.component_form {
  height: 462px;
}

.component_form .left_module:after {
  height: 100vh;
}

.topnavba {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: absolute;
  top: 108px;
  left: auto;
  z-index: 999;
  padding: 12px;
}

.topnavba.bg_next {
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .main_content.book_app_spacing_reduce {
    height: 100vh;
  }

  .component_form .right_module .form_group .btn_primary {
    /* margin-left: auto; */
  }

  .component_form {
    height: auto;
  }
}
@media (max-width: 767px) {
  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .form_group
    .list_tick_otp
    .list_otp
    li
    .field_otp {
    width: 38px;
    height: 38px;
  }

  .left_module_2 {
    margin-top: 0;
  }

  .init_information_block {
    font-weight: bold;
  }

  /* .information_block.info_block_date_picker {
    margin-top: 400px;
  } */
  /* .information_block.info_block_compare_appointment {
    margin-top: 240px;
  } */

  .logo_appointment_flow {
    height: auto;
    width: 120px;
    position: absolute;
    left: 24px;
    top: 24px;
  }

  .component_form
    .right_module
    .fillform_module
    .list_form
    > li
    .form_group
    .list_tick_otp
    .list_otp {
    padding: 0 10px;
    margin: 5px 0;
  }

  .right_module {
    padding-bottom: 0;
  }

  .component_form .right_module .fillform_module {
    width: 100%;
  }
}

.fc-scroller-harness-liquid {
  height: 270px !important;
}

.fc-scrollgrid-sync-table {
  height: 273px;
}

.fc-scroller-liquid-absolute {
  overflow: hidden !important;
}

.resendotp_block {
  max-width: 320px;
}

.email_style {
  margin-top: -26px;
}

.calLegend {
  font-size: 17px;
  width: 80%;
}
.calBlock {
  margin-top: 40px;
}

.showIfExisting {
  display: none;
}

/* MOVING PAST APPOINTMENT INLINE STYLE HERE - FOR REDUCING VERBOSITY JQUERY DOM GENERATION*/

#paListing {
  height: 348px;
  overflow: auto;
}
.pa-box_view_app-inline-style {
  border: 1px solid #0060a628;
  border-radius: 5px;
  padding: 20px 15px;
  margin: 0 0 30px;
}

.pa-txt_normal-inline-style {
  color: #000000cc;
  display: block;
  font-family: Poppins, sans-serif;
  margin: 0 0 10px;
}

.pa-txt_small-inline-style {
  font-size: 13px;
  font-weight: 600;
}

.pa-dffc-inline-style {
  color: #000000cc;
  display: block;
  font-family: Poppins, sans-serif;
  margin: 0 0 10px;
}

.pa-blueText-inline-style {
  color: #0e426a;
  font-weight: 600;
  text-align: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
